import { Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Card,
  CardHeader,
  Typography,
  CardBody,
  Input,
  Checkbox,
  CardFooter,
  Textarea,
} from "@material-tailwind/react";

interface formProps {
  handleOpen: () => void;
  isOpen: any;
}

const HelpForm = ({ handleOpen, isOpen }: formProps) => {
  return (
    <Fragment>
      <Dialog
        size="xs"
        open={isOpen}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardHeader className="mb-4 grid h-28 place-items-center bg-gradient-to-tr from-defaultBlue to-defaultBlue_60">
            <Typography variant="h3" color="white">
              Send A Message
            </Typography>
          </CardHeader>
          <CardBody className="flex flex-col gap-4">
            <Input
              variant="standard"
              label="Email"
              size="lg"
              placeholder="Your email"
            />
            <Textarea
              variant="standard"
              label="Message"
              size="lg"
              placeholder="Your message"
            />
          </CardBody>
          <CardFooter className="pt-0">
            <Button
              className="bg-gradient-to-tr from-defaultBlue to-defaultBlue_60"
              onClick={handleOpen}
              fullWidth
            >
              Send
            </Button>
          </CardFooter>
        </Card>
      </Dialog>
    </Fragment>
  );
};

export default HelpForm;
