import { Image } from "react-bootstrap";
import bigRobo from "../../images/bigRobo.png";
import { Typography } from "../../components/typography/Typography";
import CircularProgress from "./CircularProgress";

const HelloCard = () => {
  return (
    <div className="dstep1 relative h-[190px] bg-defaultBlue text-defaultWhite rounded-[20px] md:pl-[30px] pl-[40px] pr-[50px] flex justify-between items-center">
      <div className="flex justify-between mt-[40px] relative ">
        <Image className="w-[100px]" src={bigRobo} alt="..." fluid />
        <div className="mt-[45px] w-[350px] ml-5">
          <Typography.HeadingThree text="👋 Hello John" />
          <Typography.MediumText
            text="Jumpstart your journey into becoming a Roboneer today"
            xStyle="mt-[10px]"
          />

          {/* <button className="bg-defaultYellow md:w-[232px] w-[132px] md:h-[55px] h-[30px] mt-[20px] rounded-[30px]">
            Start Now
          </button> */}
        </div>
      </div>

      {/* progress bar */}
      <div className="hidden md:flex flex-col justify-center items-center">
        <CircularProgress />
        <Typography.SmallText
          text="Your Progress"
          xStyle="z-10 text-white"
        />
      </div>
    </div>
  );
};

export default HelloCard;
