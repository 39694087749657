import React, { useState, useCallback, useEffect } from "react";
import { Typography } from "../../../components/typography/Typography";
import { FiCheck } from "react-icons/fi";
import BaseService from "../../../helpers/BaseServices";
import { notification } from "antd";

interface SkillProps {
  skillTitle: string;
  color: string;
  isSelected: boolean;
  selectedSkillData: string | null; // Pass null when nothing is selected
  onSelect: () => void;
}

const SkillComponent = ({
  skillTitle,
  color,
  selectedSkillData,
  isSelected,
  onSelect,
}: SkillProps) => {
  const [isInterest, setInterest] = useState<boolean>(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState<boolean>(false);

  const registerInterest = useCallback(async () => {
    if (!selectedSkillData || alreadyRegistered) {
      console.error(
        "Selected skill data is null or interest already registered, skipping interest registration."
      );
      return;
    }

    setInterest(true);
    try {
      const payload = {
        categoryId: selectedSkillData,
      };

      const response = await BaseService.post_api(
        `/robocentre/ward-interest`,
        payload
      );
      const res = await response.data;
      console.log("Interest response: ", res);

      notification.success({
        message: `Interest in ${skillTitle} selected successfully`,
      });

      setAlreadyRegistered(true);
    } catch (error: any) {
      notification.error({
        message:
          error?.response?.data?.error ||
          "Error selecting interest. Please try again.",
      });
    } finally {
      setInterest(false);
    }
  }, [selectedSkillData, skillTitle, alreadyRegistered]);

  const handleSkillClick = () => {
    onSelect();
  };

  useEffect(() => {
    if (isSelected) {
      registerInterest();
    }
  }, [selectedSkillData, isSelected, registerInterest]);

  return (
    <div className="w-[32%]">
      <div
        className={`mt-[10px] h-[150px] cursor-pointer shadow-sm rounded-[20px] relative transform transition-transform duration-300 hover:scale-105 ${
          isInterest ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={handleSkillClick}
      >
        <div
          className="h-full rounded-[20px] relative"
          style={{ backgroundColor: color, opacity: "0.85" }}
        >
          <div className="absolute right-3 top-3">
            <div
              className={`inline-flex justify-center items-center w-[20px] h-[20px] rounded-full cursor-pointer ${
                isSelected ? "bg-[#63C477]" : "border border-white"
              }`}
            >
              {isSelected && <FiCheck size={14} color="white" />}
            </div>
          </div>

          <div className="flex justify-center items-center h-full">
            <Typography.MediumText
              text={skillTitle}
              xStyle="font-semibold text-white"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillComponent;
