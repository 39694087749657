import { Col, Image, Row } from "react-bootstrap";
import { Typography } from "../../../components/typography/Typography";
import Instructors from "./Instructors";
import { Link } from "react-router-dom";
import CurrentCourseAccordion from "./CurrentCourseAccordion";

const Overview = () => {
  return (
    <>
      <Row>
        {/* left side */}
        <Col md={9} className="mt-[10px]">
          <div className="bg-defaultWhite rounded-[20px] h-[299px] text-defaultText shadow-sm">
            <div className="relative">
              <Image
                className="h-[210px] w-full rounded-[20px] object-cover"
                src="https://images.pexels.com/photos/56866/garden-rose-red-pink-56866.jpeg?cs=srgb&dl=pexels-pixabay-56866.jpg"
                alt="..."
                fluid
              />

              <button className="w-[214px] h-[55px] bg-defaultYellow text-defaultWhite rounded-[30px] absolute bottom-[20px] right-[20px]">
                Start Learning
              </button>
            </div>

            {/* progress bars */}
            <div className="mt-[20px] mx-[23px] flex gap-[10px] justify-between items-center">
              {/* blue progress */}
              <div className="w-full">
                <div className="bg-gray-200 h-[25px] rounded-[15px]">
                  <div
                    className="bg-defaultBlue h-[25px] rounded-[15px]"
                    style={{ width: `65%` }}
                  ></div>
                </div>
                <Typography.SmallText
                  text="Term 1 - Nov. 2022-Jan. 2023"
                  xStyle="text-defaultText mt-[5px] text-center"
                />
              </div>

              {/* green progress */}
              <div className="w-full">
                <div className="bg-green-100 h-[25px] rounded-[15px]">
                  <div
                    className="bg-defaultGreen h-[25px] rounded-[15px]"
                    style={{ width: `20%` }}
                  ></div>
                </div>
                <Typography.SmallText
                  text="Term 2 - Nov. 2022-Jan. 2023"
                  xStyle="text-defaultText mt-[5px] text-center"
                />
              </div>
            </div>
          </div>

          {/* about the course */}
          <div className="mt-[30px]">
            <Typography.RegularText
              text="About the class"
              xStyle="text-defaultText"
            />

            <div className="mt-[10px] bg-defaultWhite text-defaultText px-[30px] py-[20px] rounded-[20px] shadow-sm">
              <Typography.MediumText text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. " />
            </div>
          </div>

          {/* curriculum */}
          <div className="mt-[30px]">
            <Typography.RegularText
              text="Curriculum"
              xStyle="text-defaultText"
            />

            <div className="mt-[10px] bg-defaultWhite text-defaultText px-[30px] py-[20px] rounded-[20px] shadow-sm">
              <div className="flex justify-between items-center">
                <Typography.MediumText
                  text="Current Course"
                  xStyle="font-semibold"
                />

                <Link className="text-defaultBlue font-semibold" to="#">
                  See all courses
                </Link>
              </div>

              <div className="mt-[10px]">
                <CurrentCourseAccordion />
              </div>
            </div>
          </div>
        </Col>

        {/* right side */}
        <Col md={3} className="mt-[10px]">
          <div>
            <div className="mb-[15px]">
              <Typography.MediumText
                text="Start & End Term"
                xStyle="font-semibold"
              />
              <Typography.MediumText text="Term 1 :  June 2023 - September 2023" />
              <Typography.MediumText text="Term 2 :  September 2023 - January" />
            </div>
            <div className="mb-[15px]">
              <Typography.MediumText
                text="Language of instruction"
                xStyle="font-semibold"
              />
              <Typography.MediumText text="English" />
            </div>

            <hr />

            <Typography.MediumText
              text="Instructors"
              xStyle="font-semibold my-[15px]"
            />
            <Instructors />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Overview;
