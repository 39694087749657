import { useEffect, useState } from "react";
import { Typography } from "../../../components/typography/Typography";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import ProSideBar from "../../../components/sidebar/pro-sidebar";
import SchoolName from "../../../components/navbar/SchoolName";
import TodayDate from "../../../components/navbar/TodayDate";
import CourseComponent from "./CourseComponent";
import BaseService from "../../../helpers/BaseServices";
import { Pagination, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const ChooseCourse = () => {
  const { categoryID } = useParams();

  const navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = useState<number | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [limit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [totalCourses, setTotalCourses] = useState<number>(0);
  const [current, setCurrent] = useState(1);
  const [courses, setCourses] = useState<any>([]);
  const [selectedSkill, setSelectedSkill] = useState<string | null>(null);

  const handleSelectSkill = (skill: string) => {
    setSelectedSkill(skill);
  };

  const onChange = (page: number) => {
    setCurrent(page);
    setSkip((page - 1) * limit);
  };

  const fetchAllCourses = async () => {
    setIsLoading(true);

    const filter = categoryID
      ? { "categoryData.name": { $regex: `${categoryID}`, $options: "i" } }
      : {};
    const filterString = JSON.stringify(filter);

    try {
      const response = await BaseService.get_api(
        `/robocentre/course?limit=${limit}&skip=${skip}&filter=${filterString}`
      );
      const { total, payload } = response.data;
      setCourses(payload);
      setTotalCourses(total);
      console.log("course : ", payload);
    } catch (error) {
      console.log("Error fetching payload: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCourses();
  }, [skip]);

  //
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar homeActive="active" homeIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <nav className="px-[16px] py-[20px] z-10 w-full">
          <div className="flex justify-between">
            <div className="flex gap-[20px] items-center">
              <SchoolName />
              <div className="hidden sm:hidden lg:block">
                <TodayDate />
              </div>
            </div>

            <div className="flex gap-[20px] items-center"></div>
          </div>
        </nav>

        {/* main content */}
        <div>
          <div className=" w-full h-full z-[999999] fixed top-0 left-0 bg-[#6666EA] bg-opacity-[60%] overflow-hidden">
            <div className="h-full overflow-y-auto">
              <div className="lg:px-[136px] lg:py-[76px]">
                <div className="bg-defaultBlue text-white h-[30%] flex flex-col justify-center items-center rounded-t-[20px] py-[32px]">
                  <Typography.HeadingTwo
                    text="These are the available courses available for you"
                    xStyle="text-center"
                  />
                  <Typography.MediumText
                    text="courses for you "
                    xStyle="mt-1 text-center"
                  />
                </div>

                <div className="bg-[#F7F7FD] text-defaultText px-[20px] pt-[10px] pb-[50px] rounded-b-[20px]">
                  <div className="flex flex-wrap gap-3 h-full">
                    {isLoading ? (
                      <div className="flex w-full justify-center items-center h-[300px]">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 48, color: "blue" }}
                              spin
                            />
                          }
                        />
                      </div>
                    ) : (
                      courses?.map((allCourses: any, i: number) => (
                        <CourseComponent
                          onSelect={() => setSelectedCourse(allCourses._id)}
                          active={i === selectedCourse}
                          key={allCourses._id}
                          allCourses={allCourses}
                        />
                      ))
                    )}
                  </div>
                  <div className="w-full flex justify-center items-center mt-5">
                    <Pagination
                      current={current}
                      onChange={onChange}
                      total={totalCourses}
                      pageSize={limit}
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" z-[999999] lg:px-[130px] px-[20px]   top-[92%] pt-2 pb-4  bg-[#6666EA] bg-opacity-[60%] fixed left-0  flex justify-between w-full items-center ">
            <button
              onClick={() => navigate("/choose_skill")}
              className="flex gap-2 items-center px-3 py-2 border-[2px] border-white text-white rounded-[30px]"
            >
              <ArrowLeftIcon className="h-5 w-5" />
              Back
            </button>
            <Typography.ExtraSmallText text="Page 1/3" xStyle="text-white" />
            <button
              onClick={() => navigate("/dashboard")}
              className="flex gap-2 items-center px-3 py-2 bg-defaultYellow text-white rounded-[30px]"
            >
              Next
              <ArrowRightIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseCourse;
