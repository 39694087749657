//home
export const homeTour = [
  {
    target: ".hstep1",
    content: "This is a tour",
    disableBeacon: true,
  },
  {
    target: ".hstep2",
    content: "This is a tour",
  },
  {
    target: ".hstep3",
    content: "This is a tour",
  },
  {
    target: ".hstep4",
    content: "This is a tour",
  },
  {
    target: ".hstep5",
    content: "This is a tour",
  },
];

//dashboard
export const dashboardTour = [
  {
    target: ".dstep1",
    content: "This is a welcome card",
    disableBeacon: true,
  },
  {
    target: ".dstep2",
    content: "This is a performance card",
  },
  {
    target: ".dstep3",
    content: "This is something",
  },
  {
    target: ".dstep4",
    content: "This is something",
  },
  {
    target: ".dstep5",
    content: "This is something",
  },
];

//courses
export const coursesTour = [
  {
    target: ".cstep1",
    content: "All courses, in progress and completed",
    disableBeacon: true,
  },
  {
    target: ".cstep2",
    content: "Overall progress",
  },
];

//class
export const classTour = [
  {
    target: ".ccstep1",
    content: "This is a welcome card",
    disableBeacon: true,
  },
  {
    target: ".ccstep2",
    content: "This is a performance card",
  },
  {
    target: ".ccstep3",
    content: "This is something",
  },
  {
    target: ".ccstep4",
    content: "This is something",
  },
  {
    target: ".ccstep5",
    content: "This is something",
  },
];

//badges
export const badgesTour = [
  {
    target: ".bstep1",
    content: "This is a badge",
    disableBeacon: true,
  },
];

//downloads
export const downloadsTour = [
  {
    target: ".dstep1",
    content: "This is a badge",
    disableBeacon: true,
  },
  {
    target: ".dstep2",
    content: "Click to view details of the report",
  },
  {
    target: ".dstep3",
    content: "Click to view details of the report",
  },
];

//settings
export const settingsTour = [
  {
    target: ".sstep1",
    content: "Switch between tabs",
    disableBeacon: true,
  },
  {
    target: ".sstep2",
    content: "Edit profile details",
  },
  {
    target: ".sstep3",
    content: "Change profile picture",
  },
  {
    target: ".sstep4",
    content: "Change password & language",
  },
  {
    target: ".sstep5",
    content: "Your parents",
  },
];
