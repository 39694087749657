import React, { useState, useCallback } from "react";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import { basicSchema } from "./schema";
import { Link, useNavigate } from "react-router-dom";
import { SlEye } from "react-icons/sl";
import BaseService from "../../helpers/BaseServices";
import { StorageBox } from "../../helpers/Storage";

const SignInForm = () => {
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState({
    showPassword: false,
  });

  const onSubmit = async (values: any, actions: any) => {
    try {
      const { username, password } = values;
      const res = await BaseService.post_api("/robocentre/auth/signin-ward", {
        username,
        password,
      });
      console.log("Response : ", res);
      StorageBox.saveAccessToken(res?.data?.token);
      StorageBox.saveUserData(res?.data?.payload);
      notification.success({
        message: `Logged in as ${res?.data?.payload?.firstName || "Learner"}`,
      });
      navigate("/choose_grade");
    } catch (error: any) {
      console.log("Error from authentication : ", error);
      notification.error({
        message:
          error?.response?.data?.error ||
          "Error while signing in. Please retry",
      });
    }
  };

  const ViewPassword = useCallback(() => {
    setPasswordVisibility((prev) => ({
      ...prev,
      showPassword: !prev.showPassword,
    }));
  }, []);

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: basicSchema,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit} className="">
      <div className="form-group mt-[40px]">
        <p>Username</p>
        <input
          className={`${
            errors.username && touched.username
              ? "border-[#fc8181]"
              : "border-[#9494F5]"
          } border-[1px] h-[48px] pl-2 bg-[#5D5DDC] text-white  rounded-[5px] w-full  focus:border-deeppink focus:outline-none disabled:cursor-not-allowed`}
          type="text"
          name="username"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="isaac_adams"
          required
        />
        {errors.username && touched.username && (
          <span className="text-[#fc8181]">{errors.username}</span>
        )}
      </div>
      <div className="form-group mt-[16px] ">
        <div className="relative">
          <p>Password</p>
          <input
            className={`${
              errors.password && touched.password
                ? "border-[#fc8181]"
                : "border-[#9494F5]"
            } border-[1px] h-[48px] pl-2  bg-[#5D5DDC] text-white rounded-[5px] w-full  focus:border-deeppink focus:outline-none disabled:cursor-not-allowed`}
            type={passwordVisibility.showPassword ? "text" : "password"}
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Password"
            required
          />
          <Link
            to="#"
            className="absolute right-3 bottom-[13px]"
            onClick={ViewPassword}
          >
            <SlEye className="w-5 h-5" />
          </Link>
        </div>
        {errors.password && touched.password && (
          <span className="text-[#fc8181]">{errors.password}</span>
        )}
      </div>

      <div className="form-group text-center mt-[40px]">
        <button
          className="  h-[49px] bg-defaultYellow text-white border-none font-[500] text-[18px]  rounded-[8px] form-control w-full   disabled:cursor-not-allowed disabled:bg-yellow-200 focus:bg-deeppink"
          type="submit"
          disabled={isSubmitting || !isValid}
        >
          {isSubmitting ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: "rgba(255, 196, 0, 0.2)" }}
                  spin
                />
              }
            />
          ) : (
            "Log In"
          )}
        </button>
      </div>
    </form>
  );
};

export default SignInForm;
