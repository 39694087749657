import ProSideBar from "../../../components/sidebar/pro-sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { Breadcrumb, Col, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Typography } from "../../../components/typography/Typography";
import summaryImg from "../../../images/summaryImg.png";

const Summary = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar classActive="active" classIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto overflow-x-hidden">
          {/* breadcrumbs */}
          <Breadcrumb>
            <Breadcrumb.Item className="text-defaultText">
              Quick Browse
            </Breadcrumb.Item>
            <Breadcrumb.Item className="text-defaultBlue" active>
              Booking
            </Breadcrumb.Item>
          </Breadcrumb>

          <Row className="mt-[30px]">
            <Col md={4} className="mt-[20px]">
              <Image src={summaryImg} alt="..." fluid />

              <div className="mt-[50px]">
                <Typography.RegularText
                  text="Booking Summary"
                  xStyle="text-defaultBlue font-bold"
                />
                <p className="text-defaultText">
                  Confirm details of Booking. You can
                </p>
              </div>
            </Col>
            <Col md={8} className="mt-[20px]">
              <div className="h-[298px] rounded-[20px] bg-defaultWhite text-defaultText px-[45px] pt-[20px]">
                <Typography.RegularText
                  text="Booking Summary"
                  xStyle="font-semibold"
                />
                <hr />

                <div className="mt-[25px]">
                  <div className="flex justify-between">
                    <Typography.MediumText text="Course" />
                    <Typography.MediumText
                      text="Lorem ipsum dolor sit amet"
                      xStyle="font-semibold"
                    />
                  </div>
                  <div className="flex justify-between mt-[15px]">
                    <Typography.MediumText text="Module" />
                    <Typography.MediumText
                      text="Lorem ipsum dolor sit amet"
                      xStyle="font-semibold"
                    />
                  </div>
                  <div className="flex justify-between mt-[15px]">
                    <Typography.MediumText text="Topic" />
                    <Typography.MediumText
                      text="Lorem ipsum dolor sit amet"
                      xStyle="font-semibold"
                    />
                  </div>
                  <div className="flex justify-between mt-[15px]">
                    <Typography.MediumText text="Meeting Date" />
                    <Typography.MediumText
                      text="14 Feb 2022"
                      xStyle="font-semibold"
                    />
                  </div>
                  <div className="flex justify-between mt-[15px]">
                    <Typography.MediumText text="Time" />
                    <Typography.MediumText
                      text="10:00-11:00 AM GMT"
                      xStyle="font-semibold"
                    />
                  </div>
                </div>
              </div>

              <div className="h-[50px] rounded-[20px] bg-defaultWhite mt-[10px] py-[1px] px-[45px]">
                <div className="flex justify-between mt-[15px]">
                  <Typography.MediumText text="Booking Cost" />
                  <Typography.MediumText
                    text="GH¢ 300"
                    xStyle="font-semibold text-defaultBlue"
                  />
                </div>
              </div>

              <div className="mt-[30px] flex gap-[30px] justify-end">
                <button
                  className="text-defaultText"
                  onClick={() => navigate("/book_slots")}
                >
                  Back
                </button>
                <button
                  className="text-defaultWhite bg-defaultBlue rounded-[20px] w-[139px] h-[40px]"
                  onClick={() => navigate("/book_payment")}
                >
                  Proceed
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Summary;
