import React from "react";
import { Typography } from "../../components/typography/Typography";
import ChangeLanguage from "./ChangeLanguage";

const NameAndRole = () => {
  return (
    <div className="text-center mt-[10px]">
      <Typography.MediumText
        text="Mawuli Togbor"
        xStyle="font-semibold text-defaultText"
      />
      <Typography.SmallText text="Student" xStyle="text-defaultBlue" />

      <div className="md:w-[264px] sstep4">
        {/* change password button */}
        <button className="bg-white border-[1.5px] w-[200px] border-defaultBlue_40 rounded-[20px] h-[40px] mt-[24px]">
          Change Password
        </button>

        {/* language section */}
        <ChangeLanguage />
      </div>
    </div>
  );
};

export default NameAndRole;
