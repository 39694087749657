import { Col } from 'react-bootstrap'
import { Typography } from '../../components/typography/Typography'
import { HandRaisedIcon } from '@heroicons/react/24/outline'

const TasksCompleted = () => {
  return (
    <Col md={4}>
      <div className="bg-white text-defaultText shadow-sm rounded-[20px] pt-[10px] pb-[30px] px-[15px] mt-[10px]">
        <Typography.MediumText text="Lessons Completed" />
        <div className="mt-[20px] flex justify-between items-center">
          <Typography.HeadingFour text="23k" />
          <div className="h-10 w-10 bg-defaultYellow bg-opacity-[10%] rounded-[10px] flex justify-center items-center">
            <HandRaisedIcon className="text-defaultYellow h-5 w-5" />
          </div>
        </div>
      </div>
    </Col>
  )
}

export default TasksCompleted