import nobadgesImg from "../../images/nobadgesImg.png";
import { Image } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";

const NoBadges = () => {
  return (
    <div className="mt=[30px] flex flex-col justify-center items-center">
      <Image
        className="w-[132px] h-[119px]"
        src={nobadgesImg}
        alt="..."
        fluid
      />

      <Typography.MediumText
        text="You will find your earned badges here."
        xStyle="mt-[20px] text-defaultText"
      />
    </div>
  );
};

export default NoBadges;
