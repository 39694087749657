import { useState } from "react";

const StarRating = () => {
  const [rating, setRating] = useState(0);

  const handleClick = (index: any) => {
    setRating(index + 1);
  };

  return (
    <div className="flex items-center">
      {[...Array(5)].map((_, index) => (
        <svg
          key={index}
          className={`w-6 h-6 m-[2px] fill-current ${
            index < rating ? "text-yellow-500" : "text-gray-400"
          } cursor-pointer`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          onClick={() => handleClick(index)}
        >
          <path d="M10 0l2.39 7.34h7.76l-6.27 4.55 2.39 7.34-6.27-4.55-6.27 4.55 2.39-7.34-6.27-4.55h7.76z" />
        </svg>
      ))}
    </div>
  );
};

export default StarRating;
