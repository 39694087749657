import React from "react";
import Navbar from "../../../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import { Typography } from "../../../../components/typography/Typography";
import { Form } from "react-bootstrap";
import {
  FlagIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";

const Quiz = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] md:pl-[247px] md:pr-[147px] h-full overflow-y-auto overflow-x-hidden">
          {/* top section */}
          <Link
            to="/class"
            className="text-defaultBlue border-[1.5px] border-defaultBlue text-[18px] rounded-[20px] h-[40px] w-[91px] px-2 py-1"
          >
            &#8592; Back
          </Link>

          <div className="bg-defaultBlue text-defaultWhite shadow-sm rounded-[20px] mt-[20px] h-[131px] px-[30px] pt-[27px]">
            <Typography.HeadingThree text="Advance Robotics" />
            <div className="mt-[10px] flex gap-[20px]">
              <Typography.SmallText
                text="Graded Quiz"
                xStyle="bg-tertiary-orange px-2 py-[2px] rounded-[30px]"
              />
              <Typography.MediumText text="40 mins" />
              <Typography.MediumText text="Total Points - 20" />
            </div>
          </div>

          {/* quetions */}
          <div className="bg-defaultWhite text-defaultText p-[30px] shadow-sm rounded-[20px] mt-[20px]">
            <Typography.HeadingFour text="Q1." />

            <Typography.MediumText
              text="The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs."
              xStyle="mt-[10px] mb-[20px]"
            />

            <div>
              <Form>
                <Form.Check
                  className="mb-[30px]"
                  type="radio"
                  name="option"
                  label="Option 1"
                  id="option1"
                />
                <Form.Check
                  className="mb-[30px]"
                  type="radio"
                  name="option"
                  label="Option 2"
                  id="option2"
                />
                <Form.Check
                  className="mb-[30px]"
                  type="radio"
                  name="option"
                  label="Option 3"
                  id="option3"
                />
                <Form.Check
                  className="mb-[30px]"
                  type="radio"
                  name="option"
                  label="Option 4"
                  id="option4"
                />
              </Form>
            </div>
          </div>

          {/* next and prev buttons */}
          <div className="mt-[20px] flex justify-between items-center">
            <div>
              <button className="text-defaultBlue border-[1.5px] border-defaultBlue text-[18px] rounded-[20px] h-[40px] w-[160px]">
                &#8592; Previous Page
              </button>
            </div>
            <div>
              <button className="text-defaultBlue border-[1.5px] border-defaultBlue text-[18px] rounded-[20px] h-[40px] w-[160px]">
                Next Page &#8594;
              </button>
            </div>
          </div>

          <hr className="mt-[50px] mb-[30px]" />

          {/* honor code */}
          <div className="bg-defaultWhite text-defaultText p-[30px] shadow-sm rounded-[20px] mt-[20px]">
            <Typography.MediumText
              text="RoboCentre  Honor Code."
              xStyle="mt-[10px] mb-[20px] font-semibold"
            />

            <div>
              <Form>
                <Form.Check
                  className="mb-[30px]"
                  type="radio"
                  name="optionCode"
                  label="I Agree that submitting work that isn’t my own may result in permanent failure of this course or deactivation of my Robocentre account."
                  id="optionCode"
                />

                <Form.Control
                  className="md:w-[450px] mb-[10px]"
                  placeholder="Your Full Name ..."
                />
              </Form>

              <Typography.MediumText text="Use the name on your government issued ID" />

              {/* save & submit buttons */}
              <div className="mt-[30px] flex gap-[10px] items-center">
                <div>
                  <button className="text-defaultBlue border-[1.5px] border-defaultBlue text-[18px] rounded-[20px] h-[40px] w-[154px]">
                    Save as Draft
                  </button>
                </div>
                <div>
                  <button className="text-defaultWhite bg-defaultBlue text-[18px] rounded-[20px] h-[40px] w-[110px]">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* like dislike report */}
          <div className="flex gap-[28px] mt-[30px]">
            <button className="flex gap-2 items-center justify-center text-defaultBlue font-semibold">
              <HandThumbUpIcon className="w-5 h-5" />
              Like
            </button>

            <button className="flex gap-2 items-center justify-center text-defaultBlue font-semibold">
              <HandThumbDownIcon className="w-5 h-5" />
              Dislike
            </button>

            <button className="flex gap-2 items-center justify-center text-defaultBlue font-semibold">
              <FlagIcon className="w-5 h-5" />
              Report an issue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
