import moment from 'moment/moment';
import { Typography } from '../typography/Typography';
var theDate = require('current-date');


const TodayDate = () => {
    return (
        <>
            <Typography.SmallText text={moment(theDate('full')).format('Do MMM YYYY, dddd')} />
        </>
    )
}

export default TodayDate