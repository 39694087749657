import React, { useState } from "react";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Typography } from "../../components/typography/Typography";
import { Link, useNavigate } from "react-router-dom";
import { Col, Image, Row } from "react-bootstrap";
import videoIcon from "../../images/videoIcon.png";
import {
  ChatBubbleLeftEllipsisIcon,
  DocumentPlusIcon,
  FlagIcon,
  HandThumbUpIcon,
  QueueListIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";

const DownloadDetails = () => {
  const navigate = useNavigate();
  const [sectionActive, setSectionActive] = useState(0);
  // section names
  const sectionNames = [
    {
      title: "Focus of this course",
      time: "10 mins",
      icon: <Image className="" src={videoIcon} alt="..." fluid />,
    },
    {
      title: "Focus of this course",
      time: "10 mins",
      icon: <Image className="" src={videoIcon} alt="..." fluid />,
    },
    {
      title: "Focus of this course",
      time: "10 mins",
      icon: <Image className="" src={videoIcon} alt="..." fluid />,
    },
  ];

  //section active
  const showActive = (i: any) => {
    // console.log(tabActive)
    if (i === 0 && i === sectionActive) {
      return "w-[240px] h-[55px] bg-defaultBlue_5 px-[15px] py-[5px] mt-[10px] rounded-r-full flex gap-[17px] cursor-pointer";
    } else if (i === 1 && i === sectionActive) {
      return "w-[240px] h-[55px] bg-defaultBlue_5 px-[15px] py-[5px] mt-[10px] rounded-r-full flex gap-[17px] cursor-pointer";
    } else if (i === 2 && i === sectionActive) {
      return "w-[240px] h-[55px] bg-defaultBlue_5 px-[15px] py-[5px] mt-[10px] rounded-r-full flex gap-[17px] cursor-pointer";
    } else {
      return "w-[240px] h-[55px] px-[15px] py-[5px] mt-[10px] flex gap-[17px] cursor-pointer";
    }
  };

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar downloadsActive="active" downloadsIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <div className="flex gap-[18px] items-center">
            <Link
              to="/downloads"
              className="text-defaultBlue text-[18px] px-3 py-1 border-[1px] border-defaultBlue rounded-[20px]"
            >
              &#8592; Back
            </Link>
            <Typography.HeadingThree text="Downloads Preview" />
          </div>

          <Row>
            {/* left side */}
            <Col md={3} className="mt-[20px]">
              <div className="bg-defaultWhite shadow-sm rounded-[20px] h-[537px] py-[15px] text-defaultText">
                <Typography.RegularText
                  text="Course Content"
                  xStyle="font-semibold pl-[15px]"
                />

                <div className="mt-[10px]">
                  <Typography.MediumText
                    text="Unit 1: Component"
                    xStyle="font-semibold pl-[15px]"
                  />

                  {sectionNames.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={showActive(index)}
                        onClick={setSectionActive.bind(this, index)}
                      >
                        <div className="flex items-center">{item.icon}</div>
                        <div>
                          <Typography.MediumText text={item.title} />
                          <Typography.SmallText
                            text={item.time}
                            xStyle="opacity-50"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>

            {/* right side */}
            <Col md={9} className="mt-[10px]">
              <div>
                <div className="bg-defaultWhite rounded-[20px] h-[584px] p-[15px]">
                  <div>
                    <iframe
                      className="rounded-[10px]"
                      width="100%"
                      height="500"
                      src="https://www.youtube.com/embed/f2DIwDzgb2o"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
                      allowFullScreen
                    ></iframe>
                  </div>

                  <div className="mt-[20px] justify-between items-center hidden md:flex">
                    <div className="flex gap-[40px]">
                      <button className="flex gap-2 text-defaultBlue font-semibold">
                        <DocumentPlusIcon className="w-5 h-5" />
                        Add Note
                      </button>
                      <button className="flex gap-2 text-defaultBlue font-semibold">
                        <ChatBubbleLeftEllipsisIcon className="w-5 h-5" />
                        Go to forum
                      </button>
                    </div>

                    <div className="flex gap-[40px]">
                      <button className="flex gap-2 text-defaultBlue font-semibold">
                        <QueueListIcon className="w-5 h-5" />
                        Transcript
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex gap-[28px] mt-[20px]">
                  <button className="bg-defaultBlue text-defaultWhite rounded-[20px] px-3 py-1">
                    Mark as completed
                  </button>

                  <button
                    onClick={() => navigate("/book_instructor")}
                    className="text-defaultBlue bg-defaultWhite flex gap-2 items-center justify-center border-[1px] border-defaultBlue px-3 py-1 rounded-[20px]"
                  >
                    <UserCircleIcon className="w-5 h-5 hidden md:block" />
                    Meet Instructor
                  </button>

                  <button className="flex gap-2 items-center justify-center text-defaultBlue font-semibold">
                    <HandThumbUpIcon className="w-5 h-5" />
                    Like
                  </button>

                  <button className="gap-2 items-center justify-center hidden md:flex text-defaultBlue font-semibold">
                    <FlagIcon className="w-5 h-5" />
                    Report an issue
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DownloadDetails;
