import React, { useState } from "react";
import { Image, Row } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";
import badgeImg from "../../images/badgeImg.png";
import NoBadges from "./NoBadges";
import { Link } from "react-router-dom";

const AllAchievements = () => {
  const [badges, setBadges] = useState([]);

  return (
    <Row>
      {badges.length !== 0 ? (
        <NoBadges />
      ) : (
        <>
          <SingleBadge
            badgeTitle="Badge Name"
            dateIssued="Jul. 32, 2023"
            to="/achievement_details"
          />
          <SingleBadge
            badgeTitle="Badge Name"
            dateIssued="Jul. 32, 2023"
            to="/achievement_details"
          />
          <SingleBadge
            badgeTitle="Badge Name"
            dateIssued="Jul. 32, 2023"
            to="/achievement_details"
          />
          <SingleBadge
            badgeTitle="Badge Name"
            dateIssued="Jul. 32, 2023"
            to="/achievement_details"
          />
          <SingleBadge
            badgeTitle="Badge Name"
            dateIssued="Jul. 32, 2023"
            to="/achievement_details"
          />
          <SingleBadge
            badgeTitle="Badge Name"
            dateIssued="Jul. 32, 2023"
            to="/achievement_details"
          />
        </>
      )}
    </Row>
  );
};

interface badgeProps {
  to?: any;
  badgeTitle?: String;
  dateIssued?:any;
}

//data mapper
const SingleBadge = ({to, dateIssued, badgeTitle}:badgeProps) => {
  return (
    <Link
      to={to}
      className="h-[160px] md:w-[230px] rounded-[20px] bg-defaultWhite shadow-sm ml-[17px] mb-[24px] px-[36px] py-[15px] col-md-4"
    >
      <div className="flex flex-col justify-center items-center">
        <Image src={badgeImg} alt="..." fluid />
        <Typography.MediumText
          text={`${badgeTitle}`}
          xStyle="font-semibold text-defaultText mt-[10px]"
        />
        <Typography.SmallText text={`Issued: ${dateIssued}`} />
      </div>
    </Link>
  );
};

export default AllAchievements;
