import { TrophyIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

const CircularProgress = () => {
  const [thePercentage, setThePercentage] = useState(75);
  return (
    <>
      <div className="relative inline-flex items-center justify-center overflow-hidden rounded-full">
        <svg className="w-[122px] h-[122px]">
          <circle
            className="text-defaultBlue_20"
            strokeWidth="3"
            stroke="currentColor"
            startOffset={0}
            fill="transparent"
            r="50"
            cx="60"
            cy="60"
          />
          <circle
            className="text-defaultYellow"
            strokeWidth="3"
            startOffset={0}
            strokeDasharray={320}
            strokeDashoffset={320 - (thePercentage / 100) * 320}
            strokeLinecap="round"
            stroke="currentColor"
            fill="transparent"
            r="50"
            cx="60"
            cy="60"
          />
        </svg>
        <span className="absolute z-10 text-[21px] text-defaultText font-semibold">
          <TrophyIcon className="w-[40px] h-[40px] text-defaultWhite" />
        </span>
      </div>
    </>
  );
};

export default CircularProgress;
