import { Typography } from '../typography/Typography';

const SchoolName = () => {
    return (
        <div className='bg-defaultWhite rounded-[17px] h-[28px] w-[245px] flex gap-[10px] items-center ml-10'>

            <p className='text-defaultWhite bg-defaultYellow w-[24px] h-[24px] rounded-full text-center ml-[3px]'>
                LS
            </p>

            <Typography.MediumText text='Learning Skills Int. School' />

        </div>
    )
}

export default SchoolName