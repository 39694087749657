import { MagnifyingGlassIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Col, Image, Row } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";
import { useNavigate } from "react-router-dom";

const AllDownloads = () => {
  const navigate = useNavigate();

  return (
    <Row>
      <Col lg={8}>
        <div className="bg-white shadow-sm rounded-[20px] p-[20px]">
          {/* search & filter */}
          <div className="flex justify-between items-center">
            <div className="relative dstep1">
              <input
                className="h-[40px] md:w-[230px] py-1 pl-3 md:pr-7 rounded-[20px] border-[1px] border-defaultBlue_40 focus:outline-none"
                type="text"
                placeholder="Search"
              />
              <MagnifyingGlassIcon className="h-5 w-5 text-defaultBlue absolute right-2 bottom-[10px]" />
            </div>

            <select className="dstep2 h-[40px] md:w-[190px] px-2 rounded-[20px] border-[1px] border-defaultBlue_40 focus:outline-none cursor-pointer">
              <option>All Lessons</option>
            </select>
          </div>

          {/* sample download */}
          <Row>
            <DownloadTemplate
              onView={() => navigate("/download_details")}
              onDelete={() => alert("Deleted!")}
            />
            <DownloadTemplate
              onView={() => navigate("/download_details")}
              onDelete={() => alert("Deleted!")}
            />
            <DownloadTemplate
              onView={() => navigate("/download_details")}
              onDelete={() => alert("Deleted!")}
            />
            <DownloadTemplate
              onView={() => navigate("/download_details")}
              onDelete={() => alert("Deleted!")}
            />
          </Row>
        </div>
      </Col>

      <Col lg={4}></Col>
    </Row>
  );
};

interface downloadProps {
  onDelete?: any;
  onView?: any;
}

// sample download template
const DownloadTemplate = ({ onDelete, onView }: downloadProps) => {
  return (
    <Col className="dstep3" md={12}>
      <div className="mt-[20px] p-[10px] rounded-[20px] border-[1px] border-defaultBlue_40">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-[20px]">
            <Image
              className="rounded-[10px] md:w-[130px] w-[100px] object-cover cursor-pointer"
              onClick={onView}
              src="https://www.howtogeek.com/wp-content/uploads/2021/08/YouTube-logo-hero-1.png"
              alt="..vid"
              fluid
            />
            <div>
              <Typography.SmallText
                text="Course Name | Unit 1"
                xStyle="text-defaultGray"
              />
              <Typography.MediumText
                text="Motion left and right"
                xStyle="text-defaultBlue font-semibold"
              />
              <Typography.SmallText text="10 mins" xStyle="text-defaultGray" />
            </div>
          </div>
          <button className="text-tertiary-red" onClick={onDelete}>
            <TrashIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </Col>
  );
};

export default AllDownloads;
