import { Image } from "react-bootstrap";
import { Typography } from "../../../components/typography/Typography";

const Instructors = () => {
  return (
    <>
      <div className="bg-defaultWhite rounded-[5px] outline outline-1 outline-defaultBlue_20">
        <InstructorsDataMap />
        <InstructorsDataMap />
      </div>
    </>
  );
};

//data mapper
const InstructorsDataMap = () => {
  return (
    <>
      <div className="flex gap-[18px] items-center py-[16px] pl-[16px]">
        <Image
          className="w-[36px] h-[36px] rounded-full"
          src="https://media.npr.org/assets/img/2022/11/08/ap22312071681283-0d9c328f69a7c7f15320e8750d6ea447532dff66.jpg"
          alt="tutor"
          fluid
        />

        <div>
          <Typography.MediumText text="Lorem ipsum dolor" />
          <Typography.SmallText
            text="Lorem@gmail.com"
            xStyle="text-defaultBlue"
          />
        </div>
      </div>
      <hr />
    </>
  );
};

export default Instructors;
