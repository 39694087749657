import Navbar from "../../../../components/navbar/Navbar";
import { Typography } from "../../../../components/typography/Typography";
import { Image } from "react-bootstrap";
import congratsImg from "../../../../images/congratsImg.png";
import badgeImg from "../../../../images/badgeImg.png";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import StarRating from "./StarRating";

const Congratulations = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] pl-[247px] pr-[147px] h-full overflow-y-auto overflow-x-hidden">
          {/* top section */}

          <div className="bg-defaultWhite text-defaultText py-[30px] px-[100px] h-[594px] shadow-sm rounded-[20px] mt-[20px]">
            <div className="flex gap-[35px] items-center">
              <Image src={congratsImg} alt="..." fluid />
              <div className="text-defaultText">
                <Typography.HeadingThree
                  text="Congratulations"
                  xStyle="text-secondary-green mb-[10px]"
                />
                <Typography.MediumText text="You have successfully completed" />
                <Typography.MediumText
                  text="Introduction To Robotics"
                  xStyle="font-semibold"
                />
              </div>
            </div>

            {/* new badge */}
            <div className="mt-[20px] border-[1px] border-defaultBlue_40 rounded-[20px] h-[106px] shadow-sm p-[15px]">
              <div className="flex gap-[15px] items-center">
                <Image src={badgeImg} alt="..." fluid />
                <div className="text-defaultText">
                  <Typography.SmallText
                    text="New Badge"
                    xStyle="bg-defaultYellow text-defaultWhite h-[22px] w-[99px] flex justify-center items-center rounded-[11px]"
                  />
                  <Typography.MediumText
                    text="Introduction To Robotics"
                    xStyle="font-semibold mt-[5px]"
                  />
                </div>
              </div>
            </div>

            {/* rate course */}
            <div className="mt-[20px] border-[1px] border-defaultBlue_40 rounded-[20px] h-[106px] shadow-sm p-[15px]">
              <div className="flex flex-col justify-center items-center">
                <Typography.MediumText
                  text="Rate Course"
                  xStyle="font-semibold text-defaultBlue"
                />

                <div className="mt-[10px]">
                  <StarRating />
                </div>
              </div>
            </div>

            {/* buttons */}
            <div className="mt-[30px] flex gap-[18px] justify-end">
              <button className="w-[217px] h-[40px] border-[1px] flex gap-[5px] justify-center items-center border-defaultBlue text-defaultBlue rounded-[20px]">
                <CheckBadgeIcon className="w-5 h-5" /> View my Certificate
              </button>
              <button className="w-[189px] h-[40px] bg-defaultBlue text-defaultWhite rounded-[20px]">
                Go to Next Course
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
