import React from "react";
import { Typography } from "../../../components/typography/Typography";
import { Image } from "react-bootstrap";
interface courseProps {
  onSelect: () => void;
  active: boolean;
  allCourses: any;
}
const CourseComponent = ({ onSelect, active, allCourses }: courseProps) => {
  return (
    <div
      onClick={() => onSelect()}
      className={`${
        active
          ? "border-[2px] border-defaultBlue"
          : "border-[1px] border-transparent"
      } cursor-pointer  w-[30%] pb-4  px-[10px] bg-white rounded-[20px] shadow-sm`}
    >
      <div className="relative">
        {allCourses?.banner ? (
          <Image
            className="h-[234px] w-full rounded-[20px] object-cover"
            src={`${allCourses?.banner}`}
            alt="...imgone"
            fluid
          />
        ) : (
          <Image
            className="h-[234px] w-full rounded-[20px] object-cover"
            src="https://www.timeforkids.com/wp-content/uploads/2022/02/KOTY4.jpg"
            alt="...imgone"
            fluid
          />
        )}

        <div className="text-[14px] bg-defaultYellow text-white py-1 px-4 rounded-tl-[20px] rounded-br-[20px] absolute top-0 left-0">
          Free
        </div>

        <div className="text-[14px] bg-secondary-green text-white py-1 px-4 rounded-[20px] absolute bottom-2 left-2">
          {`Ages: ${allCourses?.gradesData?.[0]?.ageGroup?.[0] ?? "N/A"} - ${
            allCourses?.gradesData?.[allCourses.gradesData.length - 1]
              ?.ageGroup?.[
              allCourses.gradesData[allCourses.gradesData.length - 1]?.ageGroup
                ?.length - 1
            ] ?? "N/A"
          }`}
        </div>
      </div>

      <div className="mt-[20px] flex items-center gap-1 mb-[10px]">
        {allCourses?.categoryData.map((cat_id: any) => (
          <div
            className="text-[14px] bg-secondary-violet bg-opacity-[10%] text-defaultText py-1 px-4 rounded-[20px]"
            key={cat_id._id}
          >
            {cat_id?.name}
          </div>
        ))}
        {/* <div className="text-[14px] bg-secondary-blue bg-opacity-[10%] text-defaultText py-1 px-4 rounded-[20px]">
          Beninner-Advance
        </div> */}
      </div>

      <Typography.MediumText
        text={`${allCourses.title}`}
        xStyle="font-semibold mt-[16px]"
      />
    </div>
  );
};

export default CourseComponent;
