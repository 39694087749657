interface noIconProps {
  onClick?: any;
  btnName?: any;
  xStyle?: any;
}

interface withIconProps {
  onClick?: any;
  btnName?: any;
  icon?: any;
}

const Filled = ({ onClick, btnName }: noIconProps) => {
  return (
    <button
      className="bg-defaultBlue text-[16px] text-defaultWhite hover:bg-defaultBlueHover px-[20px] py-[3px] w-auto rounded-[15px]"
      onClick={onClick}
    >
      {btnName}
    </button>
  );
};

const Outlined = ({ onClick, btnName }: noIconProps) => {
  return (
    <button
      className="outline outline-1 text-[16px] outline-defaultBlue text-defaultBlue hover:outline-2 hover:outline-defaultBlueHover hover:text-defaultBlueHover px-[22px] py-[2px] w-auto h-[25px] rounded-[13px]"
      onClick={onClick}
      id="outlineBtn"
    >
      {btnName}
    </button>
  );
};

const OutlinedWithIcon = ({ onClick, btnName, icon }: withIconProps) => {
  return (
    <button
      className="outline outline-1 text-[16px] outline-defaultBlue text-defaultText hover:outline-2 hover:outline-defaultBlueHover hover:text-defaultBlueHover px-[10px] pt-[2px] pb-[4px] w-auto h-[25px] rounded-[13px]"
      onClick={onClick}
    >
      <div className="flex gap-1 items-center">
        {icon}
        {btnName}
      </div>
    </button>
  );
};

export const SmallButtons = {
  Filled,
  Outlined,
  OutlinedWithIcon,
};
