import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const NavSearchBar = () => {
  return (
    <div className="">
      <input
        className="w-[272px] h-[30px] rounded-[15px] py-[5px] pl-[15px] pr-[48px] relative outline outline-2 outline-defaultBlue_20 focus:border-none"
        placeholder="Search Learning Skills Int. School"
      />
      <Link
        to="#"
        className="bg-defaultBlue_60 rounded-[15px] w-[40px] h-[24px] absolute top-[24px] right-[138px]"
      >
        <MagnifyingGlassIcon className="w-[14px] h-[14px] text-defaultWhite flex ml-auto mr-auto items-center mt-1" />
      </Link>
    </div>
  );
};

export default NavSearchBar;
