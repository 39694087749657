import React, { useEffect, useState } from "react";
import { Typography } from "../../../components/typography/Typography";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import ProSideBar from "../../../components/sidebar/pro-sidebar";
import SchoolName from "../../../components/navbar/SchoolName";
import TodayDate from "../../../components/navbar/TodayDate";
import SkillComponent from "./SkillComponent";
import BaseService from "../../../helpers/BaseServices";
import { Pagination, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const ChooseSkill = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [limit] = useState(12);
  const [skip, setSkip] = useState(0);
  const [totalCategories, setTotalCategories] = useState<number>(0);
  const [current, setCurrent] = useState(1);
  const [categories, setCategories] = useState<any>([]);
  const [selectedSkill, setSelectedSkill] = useState<string | null>(null);
  const [selectedText, setSelectedText] = useState<string | null>(null);

  const navigate = useNavigate();

  const onChange = (page: number) => {
    setCurrent(page);
    setSkip((page - 1) * limit);
  };

  const handleSelectSkill = (skill: string) => {
    setSelectedSkill(skill);
  };

  const handleSelectText = (text: string) => {
    console.log("text : ", text);
    setSelectedText(text);
  };

  const fetchAllCategories = async () => {
    setIsLoading(true);
    try {
      const response = await BaseService.get_api(
        `/robocentre/product-category?limit=${limit}&skip=${skip}`
      );
      const { total, payload } = await response.data;
      setCategories(payload);
      setTotalCategories(total);
    } catch (error) {
      console.log("Error fetching categories: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCategories();
  }, [skip]);

  useEffect(() => {
    console.log("selectedText  : ", selectedText);
  }, [selectedText]);
  return (
    <div className="flex z-[-9999] flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar homeActive="active" homeIcon="dashIconActive" />

      {/* Content */}
      <div className="flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <nav className="px-[16px] py-[20px] z-10 w-full">
          <div className="flex justify-between">
            <div className="flex gap-[20px] items-center">
              <SchoolName />
              <div className="hidden sm:hidden lg:block">
                <TodayDate />
              </div>
            </div>
            <div className="flex gap-[20px] items-center"></div>
          </div>
        </nav>

        {/* Main content */}
        <div>
          <div className="w-full h-full z-[99] fixed top-0 left-0 bg-[#6666EA] bg-opacity-[60%] overflow-hidden">
            <div className="h-full overflow-y-auto">
              <div className="lg:px-[136px] lg:py-[76px]">
                <div className="bg-defaultBlue text-white h-[30%] flex flex-col justify-center items-center rounded-t-[20px] py-[32px]">
                  <Typography.HeadingTwo
                    text="Select your interest"
                    xStyle="text-center"
                  />
                  <Typography.MediumText
                    text="We have just the right interest for you"
                    xStyle="mt-1 text-center"
                  />
                </div>

                {/* Skill list */}
                <div className="bg-white text-defaultText lg:px-[100px] px-[20px] pt-[10px] pb-[50px] rounded-b-[20px]">
                  <div className="flex flex-wrap gap-3 h-full">
                    {isLoading ? (
                      <div className="flex w-full justify-center items-center h-[300px]">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 48, color: "blue" }}
                              spin
                            />
                          }
                        />
                      </div>
                    ) : (
                      categories?.map((category: any) => (
                        <SkillComponent
                          skillTitle={category.name}
                          color={category.color}
                          key={category._id}
                          isSelected={selectedSkill === category._id}
                          selectedSkillData={selectedSkill}
                          onSelect={() => {
                            handleSelectSkill(category._id);
                            handleSelectText(category?.name);
                          }}
                        />
                      ))
                    )}
                  </div>

                  <div className="w-full flex justify-center items-center mt-5">
                    <Pagination
                      current={current}
                      onChange={onChange}
                      total={totalCategories}
                      pageSize={limit}
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="z-[999999] lg:px-[130px] px-[20px] top-[92%] pt-2 pb-4 bg-[#6666EA] bg-opacity-[60%] fixed left-0 flex justify-between w-full items-center">
            <button
              onClick={() => navigate("/choose_grade")}
              className="flex gap-2 items-center px-3 py-2 border-none border-white text-white rounded-[30px]"
            >
              {/* <ArrowLeftIcon className="h-5 w-5" />
              Back */}
            </button>
            <span className="text-white">Page {current}</span>
            <button
              onClick={() => navigate(`/choose_course/${selectedText}`)}
              className="flex gap-2 items-center px-3 py-2 bg-defaultYellow text-white rounded-[30px]"
            >
              Next
              <ArrowRightIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseSkill;
