import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { Image } from "react-bootstrap";
import { StorageBox } from "../../helpers/Storage";

const ProfilePic = () => {
  const navigate = useNavigate();

  //handle logout
  const handleLogout = () => {
    StorageBox.clearStorage();
    navigate("/");
  };

  return (
    <Menu>
      <MenuHandler>
        <div className="flex gap-1 cursor-pointer">
          <Image
            src="https://media.npr.org/assets/img/2022/11/08/ap22312071681283-0d9c328f69a7c7f15320e8750d6ea447532dff66.jpg"
            alt="Toggle"
            className="w-[32px] h-[32px] rounded-full outline outline-1 outline-white"
          />
          <ChevronDownIcon className="h-4 w-4 mt-2" />
        </div>
      </MenuHandler>
      <MenuList>
        <MenuItem>Menu Item 1</MenuItem>
        <MenuItem>Menu Item 2</MenuItem>
        <MenuItem onClick={() => handleLogout()}>Sign Out</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ProfilePic;
