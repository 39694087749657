import NavSearchBar from "./NavSearchBar";
import SchoolName from "./SchoolName";
import TodayDate from "./TodayDate";
import { OtherButtons } from "../buttons/OtherButtons";
import ProfilePic from "./ProfilePic";

const Navbar = () => {
  return (
    <nav className="px-[16px] py-[20px] z-10 w-full">
      <div className="flex justify-between">
        <div className="flex gap-[20px] items-center">
          <SchoolName />
          <div className="hidden sm:hidden lg:block">
            <TodayDate />
          </div>
        </div>

        <div className="flex gap-[20px] items-center">
          <div className="hidden md:block hstep2">
            <NavSearchBar />
          </div>

          <div className="hidden md:block hstep3">
            <OtherButtons.PlusButton />
          </div>

          <ProfilePic />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
