interface btnProps {
    onClick?:any
    btnName:String
}


const Filled = ({onClick, btnName}:btnProps) => {
    return (
        <button
            className='bg-defaultBlue text-[21px] text-defaultWhite hover:bg-defaultBlueHover px-[61px] py-[10px] w-auto h-[50px] rounded-[33px]'
            onClick={onClick}
        >
            {btnName}
        </button>
    )
}


const Outlined = ({onClick, btnName}:btnProps) => {
    return (
        <button
            className='outline outline-1 text-[21px] outline-defaultBlue text-defaultBlue hover:outline-2 hover:outline-defaultBlueHover hover:text-defaultBlueHover px-[61px] py-[10px] w-auto h-[50px] rounded-[33px]'
            onClick={onClick}
            id='outlineBtn'
        >
            {btnName}
        </button>
    )
}



export const LargeButtons = {
    Filled,
    Outlined
}