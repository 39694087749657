import { Col } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";
import { UserGroupIcon } from "@heroicons/react/24/outline";

const CompletedCourses = () => {
  return (
    <Col md={4}>
      <div className="bg-white text-defaultText shadow-sm rounded-[20px] pt-[10px] pb-[30px] px-[15px] mt-[10px]">
        <Typography.MediumText text="Courses Completed" />
        <div className="mt-[20px] flex justify-between items-center">
          <Typography.HeadingFour text="13" />
          <div className="h-10 w-10 bg-defaultBlue bg-opacity-[10%] rounded-[10px] flex justify-center items-center">
            <UserGroupIcon className="text-defaultBlue h-5 w-5" />
          </div>
        </div>
      </div>
    </Col>
  );
};

export default CompletedCourses;
