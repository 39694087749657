import { Typography } from "../../../components/typography/Typography";
import {
  ChatBubbleLeftIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import { Image } from "react-bootstrap";

const Forum = () => {
  return (
    <>
      <div className="bg-defaultWhite shadow-sm rounded-[20px] pt-[15px] pb-[30px] px-[30px] text-defaultText">
        <Typography.RegularText text="Forum" xStyle="font-semibold" />

        <div className="mt-[18px] flex items-center">
          <div className="relative hidden md:block">
            <input
              className="md:w-[339px] h-[40px] border-[1.5px] border-defaultBlue_40 rounded-[20px] px-3 shadow-none"
              type="text"
              placeholder="Search here..."
            />
            <button className="w-[130px] h-[40px] bg-defaultBlue text-defaultWhite rounded-[20px] relative right-[70px] bottom-[1px]">
              Search
            </button>
          </div>
          <button className="w-[130px] h-[40px] border-[2px] border-defaultBlue text-defaultBlue rounded-[20px]">
            Create Post
          </button>
        </div>

        <div className="mt-[18px]">
          <SampleForum />
          <SampleForum />
          <SampleForum />
        </div>
      </div>
    </>
  );
};

//data mapper
const SampleForum = () => {
  return (
    <>
      <div className="mt-[25px]">
        <div className="text-defaultText">
          <Typography.MediumText
            text="Welcome to Introduction to Robotics"
            xStyle="font-medium text-defaultBlue"
          />
          <Typography.MediumText
            text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita"
          />

          <div className="flex justify-between items-center mt-[20px]">
            <div className="mt-[20px] flex gap-[40px]">
              <button className="text-defaultBlue flex gap-[14px] justify-center items-center font-medium">
                <HandThumbUpIcon className="w-4 h-4" />
                <Typography.MediumText text="Like" />
              </button>
              <button className="text-defaultBlue flex gap-[14px] justify-center items-center font-medium">
                <ChatBubbleLeftIcon className="w-4 h-4" />
                <Typography.MediumText text="Reply" />
              </button>
            </div>

            <div className="flex gap-[20px] justify-center items-center">
              <div className="flex gap-[10px] justify-center items-center">
                <Image
                  className="h-[30px] w-[30px] rounded-full object-cover hidden md:block"
                  src="https://i.pinimg.com/736x/b9/fa/d8/b9fad8f4fb41fd671f3a8f308f3c85c5.jpg"
                  alt="..."
                  fluid
                />
                <div>
                  <Typography.MediumText
                    text="@ipsum"
                    xStyle="text-defaultText"
                  />
                </div>
              </div>
              <div>
                <Typography.SmallText
                  text="2 days ago"
                  xStyle="text-defaultText opacity-[60%]"
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="my-[20px]" />
      </div>
    </>
  );
};

export default Forum;
