import React, { useEffect, useState, useMemo } from "react";
import BaseService from "../../../helpers/BaseServices";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

interface InterestData {
  _id: string;
  categoryData: {
    color: string;
    name: string;
  };
}

const AllInterest = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [interest, setInterest] = useState<InterestData[]>([]);
  const [error, setError] = useState<string | null>(null);

  const fetchAllInterest = async () => {
    setIsLoading(true);
    try {
      const { data } = await BaseService.get_api(`/robocentre/ward-interest`);

      const { payload } = data;
      setInterest(payload);
      console.log("all interest Interest:", data);
    } catch (err) {
      setError("Failed to fetch interest data.");
      console.error("Error fetching payload:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllInterest();
  }, []);

  const loadingIcon = useMemo(
    () => <LoadingOutlined style={{ fontSize: 48, color: "blue" }} spin />,
    []
  );

  return (
    <>
      <div className="flex  flex-wrap gap-3 h-full w-full">
        {isLoading ? (
          <div className="flex w-full justify-center items-center h-[300px]">
            <Spin indicator={loadingIcon} />
          </div>
        ) : error ? (
          <div className="w-full text-center text-red-500">{error}</div>
        ) : (
          interest.map((interestItem: InterestData) => (
            <section
              key={interestItem._id}
              // style={{ backgroundColor: interestItem.categoryData.color }}
              className="h-[50px] w-[150px] border-[1px] border-solid border-defaultBlue flex justify-center items-center rounded-full"
            >
              {interestItem.categoryData.name}
            </section>
          ))
        )}
      </div>
    </>
  );
};

export default AllInterest;
