import React, { useEffect, useState } from "react";
import { Typography } from "../../../components/typography/Typography";
import { PencilIcon } from "@heroicons/react/24/outline";
import BaseService from "../../../helpers/BaseServices";
import { Button, Modal, Pagination, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import InterestComponent from "./InterestComponent";
interface CreateInterestProps {
  handleReFetch: any;
  showModal: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}
const EditInterestButton = ({
  handleReFetch,
  showModal,
  open,
  setOpen,
}: CreateInterestProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [limit] = useState(12);
  const [skip, setSkip] = useState(0);
  const [totalCategories, setTotalCategories] = useState<number>(0);
  const [current, setCurrent] = useState(1);
  const [Allinterest, setAllinterest] = useState<any>([]);
  const [selectedSkill, setSelectedSkill] = useState<string | null>(null);
  const [reFetch, setReFetch] = useState(false);

  const onChange = (page: number) => {
    setCurrent(page);
    setSkip((page - 1) * limit);
  };

  const handleSelectSkill = (skill: string) => {
    setSelectedSkill(skill);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const fetchAllCategories = async () => {
    setIsLoading(true);
    try {
      const response = await BaseService.get_api(
        `/robocentre/ward-interest?sort={"updatedAt":-1}&limit=${limit}&skip=${skip}`
      );
      const { total, payload } = await response.data;
      handleReFetch();
      console.log("edit interest : ", payload);
      setAllinterest(payload);
      setTotalCategories(total);
    } catch (error) {
      console.log("Error fetching categories: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCategories();
  }, [skip, reFetch]);

  return (
    <div>
      <Button
        type="default"
        className="flex items-center rounded-[20px]"
        onClick={showModal}
      >
        <div className="flex items-center justify-center">
          <p>
            <PencilIcon className="w-5  h-5" />
          </p>
          <p> Edit Interest</p>{" "}
        </div>
      </Button>
      <Modal
        width={"80%"}
        open={open}
        // onOk={handleOk}
        // confirmLoading={state.confirmLoading}
        footer={null}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            borderColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "40vh" } }}
      >
        <div>
          {" "}
          <p className="text-2xl text-defaultBlue mb-5 mt-1 font-body text-text_primary font-bold">
            Delete Interest
          </p>
        </div>
        <div className="flex flex-wrap gap-3 h-full">
          {isLoading ? (
            <div className="flex w-full justify-center items-center h-[300px]">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 48, color: "blue" }}
                    spin
                  />
                }
              />
            </div>
          ) : (
            Allinterest?.map((interest: any) => (
              <InterestComponent
                skillTitle={interest?.categoryData?.name}
                color={interest?.categoryData?.color}
                key={interest._id}
                isSelected={selectedSkill === interest._id}
                selectedSkillData={selectedSkill}
                onSelect={() => handleSelectSkill(interest._id)}
                handleReFetch={() => setReFetch(!reFetch)}
              />
            ))
          )}
        </div>

        <div className="w-full flex justify-center items-center mt-5">
          <Pagination
            current={current}
            onChange={onChange}
            total={totalCategories}
            pageSize={limit}
            showSizeChanger={false}
          />
        </div>
      </Modal>
    </div>
  );
};

export default EditInterestButton;
