import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import "../src/css/App.css";
import "./css/GlobalStyles.css";
import SignIn from "./pages/auth/SignIn";
import Home from "./pages/Home/Home";
import Dashboard from "./pages/Dashboard/Dashboard";
import Courses from "./pages/courses/Courses";
import CourseDetails from "./pages/courses/CourseDetails";
import Achievements from "./pages/Achievements/Achievements";
import AchievementDetails from "./pages/Achievements/AchievementDetails";
import Downloads from "./pages/downloads/Downloads";
import DownloadDetails from "./pages/downloads/DownloadDetails";
import Settings from "./pages/settings_page/Settings";
import Classroom from "./pages/Classroom/Classroom";
import Quiz from "./pages/Classroom/Quick_Browse/Quiz/Quiz";
import QuizFailed from "./pages/Classroom/Quick_Browse/Quiz/QuizFailed";
import QuizPassed from "./pages/Classroom/Quick_Browse/Quiz/QuizPassed";
import Congratulations from "./pages/Classroom/Quick_Browse/Quiz/Congratulations";
import Booking from "./pages/Classroom/Booking/Booking";
import Slots from "./pages/Classroom/Booking/Slots";
import Summary from "./pages/Classroom/Booking/Summary";
import BookingPayment from "./pages/Classroom/Booking/BookingPayment";
import ChooseGrade from "./pages/getting_started/ChooseGrade";
import ChooseSkill from "./pages/getting_started/ChooseSkills/ChooseSkill";
import ChooseCourse from "./pages/getting_started/ChooseCourse/ChooseCourse";
import PageNotFound from "./pages/404/NotFound";
import ProtectedRoute from "./HOC/ProtectedRoute";

// pages
function App() {
  return (
    <ProSidebarProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/choose_skill" />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/*" element={<PageNotFound />} />

          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Outlet />
              </ProtectedRoute>
            }
          >
            <Route path="/choose_grade" element={<ChooseGrade />} />
            <Route path="/choose_skill" element={<ChooseSkill />} />
            <Route
              path="/choose_course/:categoryID"
              element={<ChooseCourse />}
            />
            <Route path="/home" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/course_details" element={<CourseDetails />} />
            <Route path="/achievements" element={<Achievements />} />
            <Route
              path="/achievement_details"
              element={<AchievementDetails />}
            />
            <Route path="/downloads" element={<Downloads />} />
            <Route path="/download_details" element={<DownloadDetails />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/class" element={<Classroom />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/quiz_failed" element={<QuizFailed />} />
            <Route path="/quiz_passed" element={<QuizPassed />} />
            <Route path="/congratulations" element={<Congratulations />} />
            <Route path="/book_instructor" element={<Booking />} />
            <Route path="/book_slots" element={<Slots />} />
            <Route path="/book_summary" element={<Summary />} />
            <Route path="/book_payment" element={<BookingPayment />} />
          </Route>
        </Routes>
      </Router>
    </ProSidebarProvider>
  );
}

export default App;
