import { useState } from "react";
import { Typography } from "../../components/typography/Typography";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import Navbar from "../../components/navbar/Navbar";
import NavSearchBar from "../../components/navbar/NavSearchBar";
import TodayDate from "../../components/navbar/TodayDate";
import SchoolName from "../../components/navbar/SchoolName";

const ChooseGrade = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");

  //grade list
  const gradeList = [
    { grade: "Kindergarten" },
    { grade: "Grade 1 / Year 1" },
    { grade: "Grade 2 / Year 2" },
    { grade: "Grade 3 / Year 3" },
    { grade: "Grade 4 / Year 4" },
    { grade: "Grade 5 / Year 5" },
    { grade: "Grade 6 / Year 6" },
    { grade: "Grade 7 / Year 7" },
    { grade: "Grade 8 / Year 8" },
    { grade: "Grade 9 / Year 9" },
    { grade: "Grade 10 / Year 10" },
    { grade: "Grade 11 / Year 11" },
    { grade: "Grade 12 / Year 12" },
    { grade: "Grade 13 / Year 13" },
  ];

  const handleOptionsChange = (option: any) => {
    console.log(option);
    setSelectedOption(option);
  };

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar homeActive="active" homeIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <nav className="px-[16px] py-[20px] z-10 w-full">
          <div className="flex justify-between">
            <div className="flex gap-[20px] items-center">
              <SchoolName />
              <div className="hidden sm:hidden lg:block">
                <TodayDate />
              </div>
            </div>

            <div className="flex gap-[20px] items-center"></div>
          </div>
        </nav>

        {/* main content */}
        <div>
          <div className=" w-full h-full z-[999999] fixed top-0 left-0 bg-[#6666EA] bg-opacity-[60%] overflow-hidden">
            <div className="h-full overflow-y-auto">
              <div className="lg:px-[136px] lg:py-[76px]">
                <div className="bg-defaultBlue text-white h-[30%] flex flex-col justify-center items-center rounded-t-[20px] py-[32px]">
                  <Typography.HeadingTwo
                    text="What grade are you in?"
                    xStyle="text-center"
                  />
                  <Typography.MediumText
                    text="We have just the right Courses for you"
                    xStyle="mt-1 text-center"
                  />
                </div>

                {/* grades list */}
                <div className="bg-white text-defaultText lg:px-[100px] px-[20px] pt-[10px] pb-[50px] rounded-b-[20px]">
                  <div className="lg:px-[100px] flex justify-center items-center h-full">
                    <div className="lg:grid grid-cols-2 gap-x-[120px]">
                      {gradeList.map((_d, i) => (
                        <GradeComponent
                          key={i}
                          gradeTitle={_d.grade}
                          value={_d.grade}
                          isChecked={selectedOption === _d.grade}
                          onChange={() => handleOptionsChange(_d.grade)}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                {/* buttons */}
              </div>
            </div>
          </div>
          <div className=" z-[999999] lg:px-[130px] px-[20px]   top-[92%] pt-2 pb-4  bg-[#6666EA] bg-opacity-[60%] fixed left-0  flex justify-between w-full items-center ">
            <button className="flex gap-2 items-center px-3 py-2 border-[2px] border-none text-none rounded-[30px]">
              {/* <ArrowLeftIcon className="h-5 w-5" /> */}
              {/* Back */}
            </button>
            <Typography.ExtraSmallText text="Page 1/3" xStyle="text-white" />
            <button
              onClick={() => navigate("/choose_skill")}
              className="flex gap-2 items-center px-3 py-2 bg-defaultYellow text-white rounded-[30px]"
            >
              Next
              <ArrowRightIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface gradeProps {
  gradeTitle: String;
  value?: any;
  isChecked?: any;
  onChange?: () => void;
}
//smaple grade
const GradeComponent = ({
  gradeTitle,
  value,
  isChecked,
  onChange,
}: gradeProps) => {
  return (
    <div className="mt-3">
      <div className="mt-[10px] border-[1px] border-defaultBlue_40 rounded-[30px] w-[300px] py-2 px-3 flex gap-2 items-center">
        <input
          className="cursor-pointer"
          type="radio"
          value={value}
          onChange={onChange}
          checked={isChecked}
          id={value}
        />
        <label htmlFor={value} className="cursor-pointer">
          <Typography.SmallText text={gradeTitle} />
        </label>
      </div>
    </div>
  );
};

export default ChooseGrade;
