import welcomeWoman from "../../images/welcomeWoman.png";
import { Image } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";

const HelloCard = () => {
  return (
    <div className="relative h-[190px] bg-helloCard-bg text-defaultWhite rounded-[20px] pl-[40px] pr-[100px] flex justify-between items-center">
      <div className="absolute inset-0 bg-secondary-green rounded-[20px] bg-opacity-[93%]"></div>
      <div className="flex justify-between">
        <div className="text-white w-[300px] z-10">
          <Typography.HeadingThree text="Welcome to robocentre" />
          <Typography.MediumText text="Jumpstart your journey into becoming a Roboneer today" />
        </div>
        <Image className="flex justify-end absolute right-10 bottom-0" src={welcomeWoman} alt="...image" fluid />
      </div>
    </div>
  );
};

export default HelloCard;
