import React, { useState, useEffect } from "react";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Typography } from "../../components/typography/Typography";
import AllDownloads from "./AllDownloads";
import Joyride from "react-joyride";
import { downloadsTour } from "../../utils/tourSteps";
import { isTourComplete } from "../../utils/isTourComplete";

const Downloads = () => {
  //tour steps
  const [steps] = useState(downloadsTour);
  const [startTour, setStartTour] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStartTour(true);
    }, 1000);
  }, []);

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {isTourComplete ? (
        <></>
      ) : (
        <Joyride
          steps={steps}
          run={startTour}
          showSkipButton
          continuous={true}
          disableOverlayClose={true}
          showProgress
          disableCloseOnEsc={true}
          styles={{
            options: {
              arrowColor: "rgba(0, 0, 0, 0.80)",
              backgroundColor: "rgba(0, 0, 0, 0.80)",
              overlayColor: "rgba(0, 0, 0, 0.80)",
              primaryColor: "#6666EA",
              textColor: "#fff",
              zIndex: 1000,
            },
          }}
        />
      )}
      {/* Sidebar */}
      <ProSideBar downloadsActive="active" downloadsIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Typography.HeadingThree text="Downloads" />

          <div className="mt-[10px]">
            <AllDownloads />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Downloads;
