import React from "react";
import ProSideBar from "../../../components/sidebar/pro-sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { Breadcrumb, Col, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Typography } from "../../../components/typography/Typography";
import slotsImg from "../../../images/slotsImg.png";

const Slots = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar classActive="active" classIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto overflow-x-hidden">
          {/* breadcrumbs */}
          <Breadcrumb>
            <Breadcrumb.Item className="text-defaultText">
              Quick Browse
            </Breadcrumb.Item>
            <Breadcrumb.Item className="text-defaultBlue" active>
              Booking
            </Breadcrumb.Item>
          </Breadcrumb>

          <Row className="mt-[30px]">
            <Col md={4} className="mt-[20px]">
              <Image src={slotsImg} alt="..." fluid />

              <div className="mt-[50px]">
                <Typography.RegularText
                  text="Choose your slot"
                  xStyle="text-defaultBlue font-bold"
                />
                <p className="text-defaultText">
                  Availability is represented on each slot: <br />
                  <span>. White slots indicate Available</span> <br />
                  <span>. Black slots indicate Unavailable</span> <br />
                </p>
                <p className="text-defaultText mt-3">
                  Time indicated is in Greenwich Mean Time (GMT).
                </p>
              </div>
            </Col>
            <Col md={8} className="mt-[20px]">
              <div className="h-[783px] rounded-[20px] bg-defaultWhite text-defaultText px-[50px] pt-[36px]">
                <Typography.MediumText text="Date" xStyle="font-semibold" />
                <hr />

                <div className="mt-[27px] mb-[40px] h-[328px] bg-defaultBlue mx-[30px] rounded-[20px]">
                  {/* calendar */}
                </div>

                <Typography.MediumText text="Time" xStyle="font-semibold" />
                <hr />
              </div>

              <div className="mt-[30px] flex gap-[30px] justify-end">
                <button
                  className="text-defaultText"
                  onClick={() => navigate("/book_instructor")}
                >
                  Back
                </button>
                <button
                  className="text-defaultWhite bg-defaultBlue rounded-[20px] w-[139px] h-[40px]"
                  onClick={() => navigate("/book_summary")}
                >
                  Proceed
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Slots;
