import React from "react";
import { Typography } from "../../components/typography/Typography";
import signinImg from "../../images/signinImg.png";
import signinlogo from "../../images/signinlogo.png";
import { Link } from "react-router-dom";
import SignInForm from "./SignInForm";

const SignIn = () => {

  return (
    <>
      <div className="bg-defaultBlue text-white h-screen overflow-hidden">
        <div className="lg:grid lg:grid-cols-2 gap-[100px] w-full h-full lg:px-[200px]">
          {/* left */}
          <div className="mt-[10px] hidden w-full lg:flex items-center justify-center h-full">
            <div>
              <Typography.HeadingTwo text="Log In" />
              <Typography.MediumText
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et"
                xStyle="mt-[26px]"
              />

              <div className="mt-[26px]">
                <img src={signinImg} alt="signin" />
              </div>
            </div>
          </div>

          {/* right */}
          <div className="mt-[10px] flex justify-center items-center w-full h-full px-5 lg:px-0">
            <div className="w-full">
              <img
                className="flex mr-auto ml-auto w-[120px]"
                src={signinlogo}
                alt="signin"
              />

              {/* form */}
              <SignInForm />

              <div className="mt-[20px] text-white">
                <div className="flex items-center justify-center gap-2">
                  <Typography.MediumText text="Forgot password?" />
                  <Link to="#" className="hover:text-white hover:underline">
                    Reset Password
                  </Link>
                </div>
                <div className="flex items-center justify-center gap-2 mt-[20px]">
                  <Typography.MediumText text="New to Robocentre?" />
                  <Link to="#" className="hover:text-white hover:underline">
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
