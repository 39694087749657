import Navbar from "../../../../components/navbar/Navbar";
import { Typography } from "../../../../components/typography/Typography";
import { Col, Image, Row } from "react-bootstrap";
import quizPassed from "../../../../images/quizPassed.png";
import {
  BookOpenIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

const QuizPassed = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] h-full overflow-y-auto overflow-x-hidden">
          {/* top section */}
          <button className="text-defaultBlue border-[1.5px] border-defaultBlue text-[18px] rounded-[20px] ml-[88px] h-[40px] w-[91px] px-2 py-1">
            &#8592; Back
          </button>

          <Row>
            {/* left side */}
            <Col md={3} className="mt-[15px]">
              <div className="bg-defaultWhite text-defaultText h-[686px] rounded-tr-[20px] rounded-br-[20px] py-[42px] shadow-sm">
                <div className="h-[110px] w-[full] bg-defaultBlue_5 border-[8px] flex gap-[17px] border-l-defaultBlue border-y-0 border-r-0 pl-[25px] pt-[10px]">
                  <BookOpenIcon className="h-5 w-5 border-[1px] border-defaultText rounded-full p-1" />
                  <div>
                    <Typography.MediumText
                      text="Advanced Robotics"
                      xStyle="font-semibold"
                    />
                    <Typography.MediumText text="Graded Quiz" />
                    <Typography.SmallText
                      text="30 mins"
                      xStyle="text-defaultBlue"
                    />
                    <Typography.SmallText text="Due April 15, 3:30 PM GMT" />
                  </div>
                </div>
              </div>
            </Col>

            {/* right side */}
            <Col md={9} className="mt-[15px]">
              <div className="bg-defaultWhite relative pb-[60px] text-defaultText rounded-[20px] shadow-sm">
                <div className="bg-secondary-green opacity-[20%] absolute h-[146px] w-full rounded-[20px]"></div>
                <div className="flex justify-between items-center relative z-10 px-[50px] py-[17px]">
                  <div className="flex gap-[25px] items-center">
                    <Image src={quizPassed} alt="..." fluid />
                    <div className="rounded-[20px] border-[1.5px] border-secondary-green w-[161px] h-[104px] px-[20px] py-[20px] text-center">
                      <Typography.HeadingThree text="90%" />
                      <Typography.MediumText text="Grade Scored" />
                    </div>
                    <div>
                      <Typography.RegularText text="Congratulations!" />
                      <Typography.RegularText text="You passed!" />
                      <Typography.SmallText text="You have passed You scored more than 80%." />
                    </div>
                  </div>
                  <div>
                    <button className="w-[136px] h-[40px] bg-defaultBlue text-defaultWhite rounded-[30px]">
                      Go to Next
                    </button>
                  </div>
                </div>

                <div className="mt-[30px] px-[54px]">
                  <Typography.HeadingThree text="Advance Robotics" />
                  <Typography.MediumText text="Your Latest submission grade point 4/5" />
                </div>

                {/* question */}
                <div className="mt-[40px] px-[54px]">
                  <div className="flex gap-[20px]">
                    <Typography.MediumText text="Q1." xStyle="font-semibold" />
                    <Typography.MediumText text="The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs." />
                    <Typography.SmallText
                      text="1/1 point"
                      xStyle="w-[100px] h-[30px] rounded-[15px] bg-defaultBlue_5 flex justify-center items-center"
                    />
                  </div>
                  <div className="relative mt-[40px] ml-[20px]">
                    <div className="absolute bg-secondary-green opacity-[15%] w-[676px] h-[54px] rounded-[30px]"></div>
                    <div className="flex gap-[17px] items-center relative z-10 mx-[9px] top-[9px]">
                      <CheckIcon className="w-[36px] h-[36px] p-2 rounded-full bg-secondary-green text-defaultWhite" />
                      <Typography.MediumText
                        text="Correct"
                        xStyle="font-semibold text-secondary-green"
                      />
                    </div>
                  </div>
                </div>

                {/* question */}
                <div className="mt-[50px] px-[54px]">
                  <div className="flex gap-[20px]">
                    <Typography.MediumText text="Q2." xStyle="font-semibold" />
                    <Typography.MediumText text="The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs." />
                    <Typography.SmallText
                      text="1/1 point"
                      xStyle="w-[100px] h-[30px] rounded-[15px] bg-defaultBlue_5 flex justify-center items-center"
                    />
                  </div>
                  <div className="relative mt-[40px] ml-[20px]">
                    <div className="absolute bg-tertiary-pink opacity-[15%] w-[676px] h-[54px] rounded-[30px]"></div>
                    <div className="flex gap-[17px] items-center relative z-10 mx-[9px] top-[9px]">
                      <XMarkIcon className="w-[36px] h-[36px] p-2 rounded-full bg-tertiary-pink text-defaultWhite" />
                      <Typography.MediumText
                        text="Incorrect"
                        xStyle="font-semibold text-tertiary-pink"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default QuizPassed;
