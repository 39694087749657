import React from "react";
import { Typography } from "../typography/Typography";
import welcomeImg from "../../images/welcomeImg.png";
import { Image } from "react-bootstrap";

interface currProps {
  image: any;
  courseTitle: String;
  details1: String;
  details2: String;
  percent: any;
}

const CurrentCourses = ({image, courseTitle, details1, details2, percent}:currProps) => {
  return (
    <div className="border-0 shadow-sm rounded-[20px] py-[10px] pr-[20px] pl-[10px] h-[106px] bg-defaultWhite">
      <div className="flex gap-[12px]">
        {image}

        <div className="flex gap-[4px] justify-between w-full">
          <div className="h-[79px] w-[137px]">
            <Typography.MediumText
              text={courseTitle}
              xStyle="font-semibold text-defaultBlue"
            />
            <Typography.MediumText text={details1} xStyle="mt-[10px]" />
            <Typography.MediumText text={details2} />
          </div>

          <div className="relative inline-flex items-center justify-center overflow-hidden rounded-full">
            <svg className="w-[80px] h-[80px]">
              <circle
                className="text-defaultBlue_20"
                stroke-width="3"
                stroke="currentColor"
                startOffset={0}
                fill="transparent"
                r="30"
                cx="40"
                cy="40"
              />
              <circle
                className="text-defaultYellow"
                stroke-width="3"
                startOffset={0}
                strokeDasharray={188.5}
                strokeDashoffset={188.5 - (percent / 100) * 188.5}
                stroke-linecap="round"
                stroke="currentColor"
                fill="transparent"
                r="30"
                cx="40"
                cy="40"
              />
            </svg>
            <span className="absolute z-50 text-[21px] text-defaultText font-semibold">
              {percent}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface sugProps {
  image: any;
  courseTitle: String;
  details1: String;
}

const SuggestedCourses = ({ image, courseTitle, details1 }: sugProps) => {
  return (
    <div className="border-0 shadow-sm rounded-[20px] py-[10px] pr-[20px] pl-[10px] h-[106px] bg-defaultWhite">
      <div className="flex gap-[21px]">
        {image}

        <div className="h-[79px]">
          <Typography.MediumText
            text={courseTitle}
            xStyle="font-semibold text-defaultBlue"
          />
          <Typography.MediumText
            text={details1}
            xStyle="mt-[10px] line-clamp-2"
          />
        </div>
      </div>
    </div>
  );
};

const WelcomeCard = () => {
  return (
    <div className="border-0 shadow-sm rounded-[20px] pl-[20px] pt-[20px] h-[117px] bg-defaultWhite">
      <div className="flex justify-between">
        <div>
          <Typography.HeadingFour
            text="Welcome, Mawuli"
            xStyle="text-defaultBlue"
          />
          <Typography.MediumText
            text="Mawuli reached 80% of his goals this week!"
            xStyle="md:mt-[12px] text-sm"
          />
          <Typography.MediumText
            text="Keep it up and improve your results!"
            xStyle="text-sm"
          />
        </div>
        <div className="relative bottom-10 right-3">
          <Image
            src={welcomeImg}
            className="hover:translate-y-1"
            alt="..."
            fluid
          />
        </div>
      </div>
    </div>
  );
};

export const AllCards = {
  CurrentCourses,
  SuggestedCourses,
  WelcomeCard,
};
