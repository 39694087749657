interface TextProps {
  xStyle?: any;
  text: String;
}

const HeadingOne = ({ xStyle, text }: TextProps) => {
  return <h1 className={`text-[56px] font-semibold ${xStyle}`}>{text}</h1>;
};

const HeadingTwo = ({ xStyle, text }: TextProps) => {
  return <h2 className={`lg:text-[42px] text-[30px] font-semibold ${xStyle}`}>{text}</h2>;
};

const HeadingThree = ({ xStyle, text }: TextProps) => {
  return <h2 className={`text-[28px] font-semibold ${xStyle}`}>{text}</h2>;
};

const HeadingFour = ({ xStyle, text }: TextProps) => {
  return <h2 className={`text-[21px] font-semibold ${xStyle}`}>{text}</h2>;
};

const RegularText = ({ xStyle, text }: TextProps) => {
  return <p className={`text-[21px] ${xStyle}`}>{text}</p>;
};

const MediumText = ({ xStyle, text }: TextProps) => {
  return <p className={`md:text-[16px] ${xStyle}`}>{text}</p>;
};

const SmallText = ({ xStyle, text }: TextProps) => {
  return <p className={`text-[13px] ${xStyle}`}>{text}</p>;
};

const ExtraSmallText = ({ xStyle, text }: TextProps) => {
  return <p className={`text-[10px] ${xStyle}`}>{text}</p>;
};

export const Typography = {
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  HeadingFour,
  RegularText,
  MediumText,
  SmallText,
  ExtraSmallText,
};
