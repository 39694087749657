import { useState, useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Typography } from "../../components/typography/Typography";
import profile_icon from "../../images/profile_icon.png";
import notify_icon from "../../images/notify_icon.png";
import PersonalDetails from "./PersonalDetails";
import ProfilePicture from "./ProfilePicture";
import NameAndRole from "./NameAndRole";
import Parents from "./Parents";
import Joyride from "react-joyride";
import { settingsTour } from "../../utils/tourSteps";
import { isTourComplete } from "../../utils/isTourComplete";
import Notifications from "./Notifications";
import AllInterest from "./All_Interest/AllInterest";
import EditInterestButton from "./All_Interest/EditInterestButton";

const Settings = () => {
  //tour steps
  const [steps] = useState(settingsTour);
  const [startTour, setStartTour] = useState(false);
  const [open, setOpen] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setStartTour(true);
    }, 1000);
  }, []);

  const [tabActive, setTabActive] = useState(0);

  const tabNames = [
    {
      name: "Profile",
      icon: <Image className="" src={profile_icon} alt="..." fluid />,
    },
    {
      name: "Notification",
      icon: <Image className="" src={notify_icon} alt="..." fluid />,
    },
  ];

  const tabPanels = [<PersonalDetails />, <Notifications />];

  //Tab active
  const showActive = (i: any) => {
    // console.log(tabActive)
    if (i === 0 && i === tabActive) {
      return "w-fit h-[60px] flex items-center gap-[17px] pl-[10px] pr-[20px] bg-gradient-to-r from-gradient-lightGreenStart to-gradient-lightGreenEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 1 && i === tabActive) {
      return "w-fit h-[60px] flex items-center gap-[17px] pl-[10px] pr-[20px] bg-gradient-to-r from-gradient-violetStart to-gradient-violetEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else {
      return "w-fit h-[60px] flex items-center gap-[17px] pl-[10px] pr-[20px] bg-defaultWhite shadow-sm rounded-[20px]";
    }
  };

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {isTourComplete ? (
        <></>
      ) : (
        <Joyride
          steps={steps}
          run={startTour}
          showSkipButton
          continuous={true}
          disableOverlayClose={true}
          showProgress
          disableCloseOnEsc={true}
          styles={{
            options: {
              arrowColor: "rgba(0, 0, 0, 0.80)",
              backgroundColor: "rgba(0, 0, 0, 0.80)",
              overlayColor: "rgba(0, 0, 0, 0.80)",
              primaryColor: "#6666EA",
              textColor: "#fff",
              zIndex: 1000,
            },
          }}
        />
      )}

      {/* Sidebar */}
      <ProSideBar settingsActive="active" settingsIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto overflow-x-hidden">
          <Typography.HeadingThree text="Settings" />

          <Row>
            {/* left column items */}
            <Col lg={9} className="mt-[20px]">
              <div
                className="flex gap-[12px] mb-[20px] pb-2 overflow-x-auto sstep1"
                id="horizontalTab"
              >
                {tabNames.map((item, index) => (
                  <div key={index} className="bg-transparent">
                    <button
                      onClick={setTabActive.bind(this, index)}
                      key={index}
                      className={showActive(index)}
                    >
                      {item.icon}
                      <Typography.MediumText text={item.name} />
                    </button>
                  </div>
                ))}
              </div>

              <hr className="mb-[20px]" />

              <div>{tabPanels[tabActive]}</div>
              <div className="p-4 text-text_deep drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[25px] my-4 bg-white">
                <section className="flex justify-between">
                  <p className="text-[20px] text-text_deep mb-2 font-medium ">
                    Interest
                  </p>
                  <EditInterestButton
                    showModal={showModal}
                    open={open}
                    setOpen={setOpen}
                    handleReFetch={() => setReFetch(!reFetch)}
                  />
                </section>
                <hr />
                <div className="flex gap-2 flex-wrap mt-6">
                  <AllInterest />
                </div>
              </div>
            </Col>

            {/* right column items */}
            <Col className="mt-[20px]" md={3}>
              {/* profile pic section */}
              <ProfilePicture />

              {/* name section */}
              <NameAndRole />

              <hr className="my-[30px]" />

              <Parents />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Settings;
