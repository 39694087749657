import ProSideBar from "../../../components/sidebar/pro-sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { Breadcrumb, Col, Form, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Typography } from "../../../components/typography/Typography";
import paymentImg from "../../../images/paymentImg.png";
import ccImg from "../../../images/ccImg.png";

const BookingPayment = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar classActive="active" classIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto overflow-x-hidden">
          {/* breadcrumbs */}
          <Breadcrumb>
            <Breadcrumb.Item className="text-defaultText">
              Quick Browse
            </Breadcrumb.Item>
            <Breadcrumb.Item className="text-defaultBlue" active>
              Booking
            </Breadcrumb.Item>
          </Breadcrumb>

          <Row className="mt-[30px]">
            <Col md={4} className="mt-[20px]">
              <Image src={paymentImg} alt="..." fluid />

              <div className="mt-[50px]">
                <Typography.RegularText
                  text="Add card Details"
                  xStyle="text-defaultBlue font-bold"
                />
                <p className="text-defaultText">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam
                </p>
              </div>
            </Col>
            <Col md={8} className="mt-[20px]">
              <div className="h-[406px] rounded-[20px] bg-defaultWhite text-defaultText px-[45px] pt-[20px]">
                <Image src={ccImg} alt="..." fluid />

                <div className="mt-[35px]">
                  <Form>
                    <Form.Group className="flex justify-between items-center">
                      <Typography.MediumText text="Card Name" />
                      <Form.Control
                        className="shadow-none border-[1px] border-defaultBlue_20 rounded-[20px] w-[420px] h-[40px]"
                        type="text"
                        placeholder="Name on as shown on the card"
                      />
                    </Form.Group>
                    <Form.Group className="mt-[20px] flex justify-between items-center">
                      <Typography.MediumText text="Card Number" />
                      <Form.Control
                        className="shadow-none border-[1px] border-defaultBlue_20 rounded-[20px] w-[420px] h-[40px]"
                        type="number"
                        placeholder="0000-0000-0000-0000"
                      />
                    </Form.Group>
                    <Form.Group className="mt-[20px] flex justify-between items-center">
                      <Typography.MediumText text="Expiry Date" />
                      <Form.Control
                        className="shadow-none border-[1px] border-defaultBlue_20 rounded-[20px] w-[420px] h-[40px]"
                        type="number"
                        placeholder="MM / YY"
                      />
                    </Form.Group>
                    <Form.Group className="mt-[20px] flex justify-between items-center">
                      <Typography.MediumText text="CVV / CVC" />
                      <Form.Control
                        className="shadow-none border-[1px] border-defaultBlue_20 rounded-[20px] w-[420px] h-[40px]"
                        type="number"
                        placeholder="123"
                      />
                    </Form.Group>
                  </Form>
                </div>
              </div>

              <div className="mt-[30px] flex gap-[30px] justify-end">
                <button
                  className="text-defaultText"
                  onClick={() => navigate("/book_summary")}
                >
                  Back
                </button>
                <button
                  className="text-defaultWhite bg-defaultBlue rounded-[20px] w-[139px] h-[40px]"
                  onClick={() => alert("Paid")}
                >
                  Make Payment
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default BookingPayment;
