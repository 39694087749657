import Navbar from "../../../components/navbar/Navbar";
import ProSideBar from "../../../components/sidebar/pro-sidebar";
import { Breadcrumb, Col, Form, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import bookingImg from "../../../images/bookingImg.png";
import { Typography } from "../../../components/typography/Typography";
import { PlusIcon } from "@heroicons/react/24/outline";

const Booking = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar classActive="active" classIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto overflow-x-hidden">
          {/* breadcrumbs */}
          <Breadcrumb>
            <Breadcrumb.Item
              className="text-defaultText"
              onClick={() => navigate("/class")}
            >
              Quick Browse
            </Breadcrumb.Item>
            <Breadcrumb.Item className="text-defaultBlue" active>
              Booking
            </Breadcrumb.Item>
          </Breadcrumb>

          <Row className="mt-[30px]">
            <Col md={4} className="mt-[20px]">
              <Image src={bookingImg} alt="..." fluid />

              <div className="mt-[50px]">
                <Typography.RegularText
                  text="Book an Appointment"
                  xStyle="text-defaultBlue font-bold"
                />
                <Typography.SmallText
                  text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam"
                  xStyle="text-defaultText"
                />
              </div>
            </Col>
            <Col md={8} className="mt-[20px]">
              <div className="h-[297px] rounded-[20px] bg-defaultWhite text-defaultText px-[50px] pt-[36px]">
                <div className="flex gap-[50px]">
                  <Typography.MediumText text="Course" />
                  <Form.Select className="rounded-[50px] shadow-none">
                    <option>Large select</option>
                  </Form.Select>
                </div>
                <div className="flex gap-[50px] mt-[20px]">
                  <Typography.MediumText text="Module" />
                  <Form.Select className="rounded-[50px] shadow-none">
                    <option>Large select</option>
                  </Form.Select>
                </div>
                <div className="flex gap-[50px] mt-[20px]">
                  <Typography.MediumText text="Topics" />
                  <Form.Select className="rounded-[50px] shadow-none">
                    <option>Large select</option>
                  </Form.Select>
                </div>

                <div className="flex justify-end gap-[15px] mt-[20px]">
                  <Typography.MediumText text="Add topic" />
                  <PlusIcon className="w-6 h-6 bg-defaultYellow text-defaultWhite rounded-full cursor-pointer" />
                </div>
              </div>

              <div className="mt-[30px] flex gap-[30px] justify-end">
                <button
                  className="text-defaultText"
                  onClick={() => navigate("/class")}
                >
                  Back
                </button>
                <button
                  className="text-defaultWhite bg-defaultBlue rounded-[20px] w-[139px] h-[40px]"
                  onClick={() => navigate("/book_slots")}
                >
                  Proceed
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Booking;
