import {
  ArrowDownTrayIcon,
  ArrowRightIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  PlusIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";

interface btnProps {
  onClick?: any;
  btnName: String;
}

const DislikeButton = ({ onClick, btnName }: btnProps) => {
  return (
    <button
      onClick={onClick}
      className="text-defaultBlue font-semibold text-[16px] hover:border-b-2 border-defaultBlueHover"
    >
      <div className="flex gap-2 items-center">
        <HandThumbDownIcon className="w-5 h-5" />
        {btnName}
      </div>
    </button>
  );
};

const DislikedButton = ({ onClick, btnName }: btnProps) => {
  return (
    <button
      onClick={onClick}
      className="text-tertiary-red font-semibold text-[16px]"
    >
      <div className="flex gap-2 items-center">
        <HandThumbDownIcon className="w-5 h-5" />
        {btnName}
      </div>
    </button>
  );
};

const Like = ({ onClick, btnName }: btnProps) => {
  return (
    <button
      onClick={onClick}
      className="text-defaultBlue font-semibold text-[16px] hover:border-b-2 border-defaultBlueHover"
    >
      <div className="flex gap-2">
        <HandThumbUpIcon className="w-5 h-5" />
        {btnName}
      </div>
    </button>
  );
};

const Liked = ({ onClick, btnName }: btnProps) => {
  return (
    <button
      onClick={onClick}
      className="text-tertiary-green font-semibold text-[16px]"
    >
      <div className="flex gap-2">
        <HandThumbUpIcon className="w-5 h-5" />
        {btnName}
      </div>
    </button>
  );
};

const SeeAll = ({ onClick, btnName }: btnProps) => {
  return (
    <button
      onClick={onClick}
      className="text-defaultBlue font-semibold text-[16px] hover:text-defaultBlueHover"
    >
      <div className="flex gap-2 items-center">
        {btnName}
        <ArrowRightIcon className="w-4 h-4" />
      </div>
    </button>
  );
};

const PlusButton = (onClick: any) => {
  return (
    <button
      className="bg-defaultBlue rounded-full w-[26px] h-[26px] flex justify-center items-center"
      onClick={onClick}
    >
      <PlusIcon className="w-5 h-5 text-defaultWhite" />
    </button>
  );
};

const PrintButton = (onClick: any) => {
  return (
    <button
      id="iconButtons"
      className="outline outline-1 outline-defaultBlue rounded-[5px] w-[40px] h-[40px] flex justify-center items-center hover:outline hover:outline-2 hover:outline-defaultBlueHover"
      onClick={onClick}
    >
      <PrinterIcon className="w-5 h-5 text-defaultBlue" />
    </button>
  );
};

const DownloadButton = (onClick: any) => {
  return (
    <button
      id="iconButtons"
      className="outline outline-1 outline-defaultBlue rounded-[5px] w-[40px] h-[40px] flex justify-center items-center hover:outline hover:outline-2 hover:outline-defaultBlueHover"
      onClick={onClick}
    >
      <ArrowDownTrayIcon className="w-5 h-5 text-defaultBlue" />
    </button>
  );
};

export const OtherButtons = {
  DislikeButton,
  DislikedButton,
  Like,
  Liked,
  SeeAll,
  PlusButton,
  PrintButton,
  DownloadButton,
};
