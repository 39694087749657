import { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import {
  ArrowDownTrayIcon,
  Bars3Icon,
  CalendarDaysIcon,
  ChatBubbleLeftEllipsisIcon,
  CodeBracketIcon,
  Cog6ToothIcon,
  FolderIcon,
  HomeIcon,
  Squares2X2Icon,
  TrophyIcon,
  UsersIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import logoImg from "../../images/logo.png";
import { Typography } from "../typography/Typography";
import HamburgerMenu from "../navbar/HamburgerMenu";
import { Tooltip } from "@material-tailwind/react";
import HelpForm from "../others/HelpForm";

interface sidebarProps {
  homeIcon?: any;
  homeActive?: any;
  dashboardActive?: any;
  dashIcon?: any;
  calendarActive?: any;
  calenIcon?: any;
  timetableActive?: any;
  iconActive?: any;
  coursesActive?: any;
  coursesIcon?: any;
  classIcon?: any;
  classActive?: any;
  achieActive?: any;
  achieIcon?: any;
  downloadsIcon?: any;
  downloadsActive?: any;
  settingsIcon?: any;
  settingsActive?: any;
}

const ProSideBar = ({
  homeIcon,
  homeActive,
  dashboardActive,
  dashIcon,
  calendarActive,
  calenIcon,
  iconActive,
  timetableActive,
  coursesActive,
  coursesIcon,
  classActive,
  classIcon,
  achieActive,
  achieIcon,
  downloadsActive,
  downloadsIcon,
  settingsActive,
  settingsIcon,
}: sidebarProps) => {
  const { collapseSidebar } = useProSidebar();
  const [sidebarVisibility, setSidebarVisibilty] = useState(
    localStorage.getItem("side_visibilty") === "true" || false
  );

  const toggleSideBar = () => {
    const newVisibilty = !sidebarVisibility;
    setSidebarVisibilty(newVisibilty);
    localStorage.setItem("side_visibilty", newVisibilty.toString());
  };

  useEffect(() => {
    const storedVisibilty = localStorage.getItem("side_visibilty");
    if (storedVisibilty) {
      setSidebarVisibilty(storedVisibilty === "true");
    }
  }, []);

  //toggle sidebar on mobile
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const handleToggle = () => {
    if (toggleSidebar) {
      setToggleSidebar(false);
    } else {
      setToggleSidebar(true);
    }
  };

  const tooltipAnimation = {
    mount: { scale: 1, y: 0 },
    unmount: { scale: 0, y: 25 },
  };

  const [helpOpen, setHelpOpen] = useState(false);

  const handleFormOpen = () => setHelpOpen(!helpOpen);

  return (
    <>
      <div className="absolute top-5 z-50 left-3">
        <HamburgerMenu onClick={() => handleToggle()} />
      </div>
      <div
        className={`h-[100vh] bg-white shadow-sm overflow-y-auto overflow-x-hidden lg:block ${
          toggleSidebar ? "block absolute z-50" : "hidden"
        }`}
        id="mySidebar"
      >
        <Sidebar collapsed={sidebarVisibility}>
          <Menu className="relative">
            {/* logo */}
            <div className="flex gap-[24px] ml-6 mt-[15px]">
              <Link
                className={sidebarVisibility ? "" : "hidden lg:block"}
                to="#"
                onClick={() => {
                  collapseSidebar();
                  toggleSideBar();
                }}
              >
                <Bars3Icon className="w-[30px] h-[30px] text-defaultBlue" />
              </Link>
              <div>
                {sidebarVisibility ? (
                  ""
                ) : (
                  <Image src={logoImg} alt="logo" fluid />
                )}
              </div>
            </div>

            <div className="flex justify-end mx-3 absolute top-0 right-0 lg:hidden">
              <XCircleIcon
                className="w-[30px] h-[30px] text-defaultBlue"
                onClick={() => setToggleSidebar(false)}
              />
            </div>

            <br />

            {/* menus */}
            <div className="hstep1">
              <MenuItem
                icon={
                  <HomeIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${homeIcon}`}
                  />
                }
                className={homeActive}
                component={<Link to="/home" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Home
                </p>
              </MenuItem>

              <MenuItem
                icon={
                  <Squares2X2Icon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${dashIcon}`}
                  />
                }
                className={dashboardActive}
                component={<Link to="/dashboard" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Dashboard
                </p>
              </MenuItem>

              {/* <MenuItem
                icon={
                  <CalendarDaysIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${calenIcon}`}
                  />
                }
                className={calendarActive}
                component={<Link to="#" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Calendar
                </p>
              </MenuItem> */}

              {/* <MenuItem
                icon={
                  <CalendarDaysIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${iconActive}`}
                  />
                }
                className={timetableActive}
                component={<Link to="#" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Time table
                </p>
              </MenuItem> */}

              <MenuItem
                icon={
                  <FolderIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${coursesIcon}`}
                  />
                }
                className={coursesActive}
                component={<Link to="/courses" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Courses
                </p>
              </MenuItem>

              <MenuItem
                icon={
                  <UsersIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${classIcon}`}
                  />
                }
                className={classActive}
                component={<Link to="/class" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Class
                </p>
              </MenuItem>

              <MenuItem
                icon={
                  <TrophyIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${achieIcon}`}
                  />
                }
                className={achieActive}
                component={<Link to="/achievements" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Achievments
                </p>
              </MenuItem>

              <hr className="text-defaultBlue_40 mt-[20px] mx-[27px]" />

              <MenuItem
                icon={
                  <ArrowDownTrayIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${downloadsIcon}`}
                  />
                }
                className={`mt-[20px] ${downloadsActive}`}
                component={<Link to="/downloads" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Downloads
                </p>
              </MenuItem>

              <MenuItem
                icon={
                  <Cog6ToothIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${settingsIcon}`}
                  />
                }
                className={`mt-[20px] ${settingsActive}`}
                component={<Link to="/settings" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Settings
                </p>
              </MenuItem>

              {sidebarVisibility ? (
                <div
                  onClick={() => handleFormOpen()}
                  className="mt-[67px] mb-[20px] ml-7"
                >
                  <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-defaultYellow cursor-pointer" />
                </div>
              ) : (
                <div
                  onClick={() => handleFormOpen()}
                  className="mt-[67px] mb-[20px] ml-6 text-defaultText flex items-center gap-3 cursor-pointer"
                >
                  <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-defaultYellow" />
                  <Typography.MediumText text="Need Help?" />
                </div>
              )}

              {/* code with gBlox */}
              {sidebarVisibility ? (
                <div
                  className="mx-2 h-[30px] bg-defaultBlue rounded-t-[10px] mt-[45px] py-[8px] text-defaultWhite cursor-pointer"
                  onClick={() => alert("ye")}
                >
                  <div className="flex justify-center items-center">
                    <CodeBracketIcon className="h-5 w-5" />
                  </div>
                </div>
              ) : (
                <div
                  className="mx-6 h-[37px] bg-defaultBlue rounded-t-[18px] mt-[45px] py-[8px] text-defaultWhite cursor-pointer"
                  onClick={() => alert("ye")}
                >
                  <div className="flex gap-[10px] justify-center items-center">
                    <CodeBracketIcon className="h-5 w-5" />
                    <Typography.MediumText text="Code in gBlox" />
                  </div>
                </div>
              )}
            </div>
          </Menu>
        </Sidebar>
      </div>

      {helpOpen ? (
        <HelpForm isOpen={helpOpen} handleOpen={handleFormOpen} />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProSideBar;
