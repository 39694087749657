import React, { useEffect, useState } from "react";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import Navbar from "../../components/navbar/Navbar";
import HelloCard from "./HelloCard";
import RecentCourses from "../settings_page/All_Interest/AllInterest";
import Joyride from "react-joyride";
import { homeTour } from "../../utils/tourSteps";
import { isTourComplete } from "../../utils/isTourComplete";
import { Alert, Space } from "antd";

const Home = () => {
  //tour steps
  const [steps] = useState(homeTour);
  const [startTour, setStartTour] = useState(false);

  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setStartTour(true);
    }, 1000);
  }, []);

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {isTourComplete ? (
        <></>
      ) : (
        <Joyride
          steps={steps}
          run={startTour}
          showSkipButton
          continuous={true}
          disableOverlayClose={true}
          showProgress
          disableCloseOnEsc={true}
          floaterProps={{ placement: "right" }}
          styles={{
            options: {
              arrowColor: "rgba(0, 0, 0, 0.80)",
              backgroundColor: "rgba(0, 0, 0, 0.80)",
              overlayColor: "rgba(0, 0, 0, 0.80)",
              primaryColor: "#6666EA",
              textColor: "#fff",
              zIndex: 1000,
            },
          }}
        />
      )}
      {/* Sidebar */}
      <ProSideBar homeActive="active" homeIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Space
            className="mb-[20px]"
            direction="vertical"
            style={{ width: "100%" }}
          >
            {visible && (
              <Alert
                message="Your parent or guardian must approve your account or it will be deleted in 7 days."
                type="warning"
                closable
                afterClose={handleClose}
                showIcon
              />
            )}
          </Space>

          <HelloCard />

          {/* recent courses */}
          {/* <RecentCourses /> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
