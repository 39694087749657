import ProSideBar from "../../components/sidebar/pro-sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Typography } from "../../components/typography/Typography";
import {
  ArrowLeftIcon,
  CheckIcon,
  DevicePhoneMobileIcon,
  ComputerDesktopIcon,
  HandThumbUpIcon,
  HandThumbDownIcon,
  FlagIcon,
  ShareIcon,
  ChevronDownIcon,
  ClockIcon,
  BookOpenIcon,
  ClipboardDocumentIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { Col, Image, Row, Table } from "react-bootstrap";
import mello_bot from "../../images/mello_bot.png";
import gblox_icon from "../../images/gblox_icon.png";
import { Link, useNavigate } from "react-router-dom";
import { Disclosure } from "@headlessui/react";

const CourseDetails = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-row overflow-hidden h-[100vh] w-full">
        {/* Sidebar */}
        <ProSideBar coursesActive="active" coursesIcon="dashIconActive" />

        {/* Content */}
        <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
          <Navbar />

          {/* main content */}
          <div className="container-fluid pt-2 pb-[58px] px-[16px] mb-[20px] h-full overflow-y-auto text-defaultText">
            <div className="flex gap-[20px] items-center">
              <button
                onClick={() => navigate("/courses")}
                className="flex gap-[5px] items-center justify-center text-defaultBlue h-[40px] w-[90px] rounded-[30px] border border-defaultBlue"
              >
                <ArrowLeftIcon className="w-3 h-3" />
                Back
              </button>
              <Typography.HeadingThree text="Basic Electronics" />
            </div>

            {/* image card */}
            <div className="mt-[10px] p-[10px] rounded-[20px] w-[888px] h-[333px] shadow-sm bg-defaultWhite">
              <Image
                className="h-[200px] w-full rounded-[10px] object-cover"
                src="https://www.solvay.com/sites/g/files/srpend221/files/styles/halfwidth/https/media.solvay.com/medias/domain1446/media100750/225187-geoul1o2uu.jpg"
                alt="..."
                fluid
              />
              <div className="mt-[30px]">
                <ol className="flex items-center">
                  <li className="flex w-full items-center text-secondary-green after:content-[''] after:w-full after:h-1 after:border-secondary-green  after:border-2 after:inline-block">
                    <span className="flex items-center justify-center w-10 h-10 bg-secondary-green rounded-full lg:h-12 lg:w-12 shrink-0">
                      <CheckIcon className="w-5 h-5 text-defaultWhite" />
                    </span>
                  </li>
                  <li className="flex w-full items-center text-secondary-green after:content-[''] after:w-full after:h-1 after:border-secondary-green  after:border-2 after:inline-block">
                    <span className="flex items-center justify-center w-10 h-10 bg-secondary-green rounded-full lg:h-12 lg:w-12 shrink-0">
                      <CheckIcon className="w-5 h-5 text-defaultWhite" />
                    </span>
                  </li>
                  <li className="flex w-full items-center text-defaultBlue_40 after:content-[''] after:w-full after:h-1 after:border-defaultBlue_40  after:border-2 after:inline-block">
                    <span className="flex items-center justify-center w-10 h-10 bg-defaultBlue rounded-full lg:h-12 lg:w-12 shrink-0">
                      <ClipboardDocumentIcon className="w-5 h-5 text-defaultWhite" />
                    </span>
                  </li>
                  <li className="flex w-full items-center text-defaultBlue_40 after:content-[''] after:w-full after:h-1 after:border-defaultBlue_40  after:border-2 after:inline-block">
                    <span className="flex items-center justify-center w-10 h-10 bg-defaultYellow rounded-full lg:h-12 lg:w-12 shrink-0">
                      <ClockIcon className="w-5 h-5 text-defaultWhite" />
                    </span>
                  </li>
                  <li className="flex w-full items-center text-defaultBlue_40 after:content-[''] after:w-full after:h-1 after:border-defaultBlue_40  after:border-2 after:inline-block">
                    <span className="flex items-center justify-center w-10 h-10 bg-defaultBlue_5 border-[2px] border-defaultBlue rounded-full lg:h-12 lg:w-12 shrink-0">
                      <ClipboardDocumentIcon className="w-5 h-5 text-defaultBlue" />
                    </span>
                  </li>
                  <li className="flex items-center text-defaultBlue_40 after:content-[''] after:inline-block">
                    <span className="flex items-center justify-center w-10 h-10 bg-defaultBlue_5 border-[2px] border-defaultBlue rounded-full lg:h-12 lg:w-12 shrink-0">
                      <ClipboardDocumentIcon className="w-5 h-5 text-defaultBlue" />
                    </span>
                  </li>
                </ol>
              </div>
            </div>

            {/* about course */}
            <Typography.RegularText
              text="About the course"
              xStyle="mt-[50px]"
            />
            <div className="mt-[10px] px-[37px] py-[20px] rounded-[20px] w-[888px] shadow-sm bg-defaultWhite">
              {/* div 1 */}
              <div>
                <Typography.MediumText
                  text="Coures Description"
                  xStyle="font-semibold"
                />
                <Typography.MediumText
                  text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur"
                  xStyle="mt-[10px]"
                />
              </div>
              {/* div 2 */}
              <div className="mt-[20px]">
                <Typography.MediumText
                  text="What you will learn"
                  xStyle="font-semibold"
                />
                <div className="flex gap-[16px] items-center">
                  <CheckIcon className="w-5 h-5 text-defaultGreen" />
                  <Typography.MediumText
                    text="Within a course, there are suggested due dates to help you
                        manage your schedule and keep coursework from piling up."
                    xStyle="mt-[10px]"
                  />
                </div>
                <div className="flex gap-[16px] items-center">
                  <CheckIcon className="w-5 h-5 text-defaultGreen" />
                  <Typography.MediumText
                    text="Within a course, there are suggested due dates to help you
                        manage your schedule and keep coursework from piling up."
                    xStyle="mt-[10px]"
                  />
                </div>
              </div>
              {/* div 3 */}
              <div className="mt-[30px]">
                <Typography.MediumText
                  text="The Numbers"
                  xStyle="font-semibold"
                />

                <Table className="mt-[30px]" striped>
                  <tbody>
                    <tr>
                      <td className="font-semibold">Level</td>
                      <td>Begginers</td>
                    </tr>
                    <tr>
                      <td className="font-semibold">Course Duration</td>
                      <td>5 weeks of study, 1-2 hours/week</td>
                    </tr>
                    <tr>
                      <td className="font-semibold">Language</td>
                      <td>English</td>
                    </tr>
                    <tr>
                      <td className="font-semibold">Caption</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td className="font-semibold">How to Pass</td>
                      <td>
                        Pass all graded assignments to complete the course.
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold">User Ratings</td>
                      <td>Average User Rating 4.5</td>
                    </tr>
                    <tr>
                      <td className="font-semibold">Features</td>
                      <td>Available on IOS and Android</td>
                    </tr>
                    <tr>
                      <td className="font-semibold">Students</td>
                      <td>26748</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            {/* instructor */}
            <Typography.RegularText text="The Instructor" xStyle="mt-[50px]" />
            <div className="mt-[10px] px-[30px] py-[34px] rounded-[20px] w-[888px] shadow-sm bg-defaultWhite">
              <div className="flex gap-[20px]">
                <Image
                  className="w-[48px] h-[48px] rounded-full object-cover    "
                  src="https://guardian.ng/wp-content/uploads/2020/07/teacher.jpg"
                  alt="..."
                  fluid
                />
                <div>
                  <Typography.MediumText
                    text="Kwabena Ntow"
                    xStyle="font-semibold"
                  />
                  <Typography.SmallText
                    text="Instructor"
                    xStyle="text-defaultBlue"
                  />
                </div>
              </div>
              <Typography.MediumText
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur"
                xStyle="mt-[20px]"
              />
            </div>

            {/* Prerequisite */}
            <Typography.RegularText text="Prerequisite" xStyle="mt-[50px]" />
            <div className="mt-[10px] px-[30px] py-[34px] rounded-[20px] w-[888px] shadow-sm bg-defaultWhite">
              <Typography.MediumText text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur" />
            </div>

            {/* Tools */}
            <Typography.RegularText text="Tools" xStyle="mt-[50px]" />
            <div className="mt-[10px] py-[34px] rounded-[20px] w-[888px] shadow-sm bg-defaultWhite">
              {/* div 1 */}
              <div className="w-full bg-[#f8f8f8] py-[18px] px-[30px]">
                <Row>
                  <Col md={4}>
                    <div className="flex gap-[27px] items-center">
                      <Image className="" src={mello_bot} alt="..." fluid />
                      <div>
                        <Typography.MediumText text="Mello Robot" />
                        <Typography.SmallText
                          text="GHS 1,200.00"
                          xStyle="text-defaultBlue"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    md={1}
                    className="border-[2px] border-l-[#c0c0c2] border-y-0 border-r-0"
                  ></Col>
                  <Col
                    md={7}
                    className="flex flex-col gap-[10px] justify-center w-[100px]"
                  >
                    <Link
                      className="text-defaultBlue text-[14px] font-semibold"
                      to="#"
                    >
                      Add to Cart
                    </Link>
                    <Link
                      className="text-defaultBlue text-[14px] font-semibold"
                      to="#"
                    >
                      Buy Now
                    </Link>
                  </Col>
                </Row>
              </div>

              {/* div 2 */}
              <div className="w-full bg-[#f8f8f8] py-[18px] px-[30px] mt-[40px]">
                <Row>
                  <Col md={4}>
                    <div className="flex gap-[27px] items-center">
                      <Image className="" src={gblox_icon} alt="..." fluid />
                      <div>
                        <Typography.MediumText text="GBlox App" />
                        <Typography.SmallText
                          text="Free"
                          xStyle="text-defaultBlue"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    md={1}
                    className="border-[2px] border-l-[#c0c0c2] border-y-0 border-r-0"
                  ></Col>
                  <Col md={7}>
                    <div className="flex gap-[30px] items-center">
                      {/* mobile download */}
                      <div>
                        <Typography.MediumText text="Download Gblox mobile App" />
                        <div className="flex gap-[19px] items-center">
                          <DevicePhoneMobileIcon className="w-5 h-10" />
                          <div className="flex flex-col">
                            <Link
                              className="text-defaultBlue text-[14px] font-semibold"
                              to="#"
                            >
                              Download for Android
                            </Link>
                            <Link
                              className="text-defaultBlue text-[14px] font-semibold"
                              to="#"
                            >
                              Download for iOS
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* desktop download */}
                      <div>
                        <Typography.MediumText text="Download Gblox Desktop App" />
                        <div className="flex gap-[19px] items-center">
                          <ComputerDesktopIcon className="w-5 h-10" />
                          <div className="flex flex-col">
                            <Link
                              className="text-defaultBlue text-[14px] font-semibold"
                              to="#"
                            >
                              Download for Windows
                            </Link>
                            <Link
                              className="text-defaultBlue text-[14px] font-semibold"
                              to="#"
                            >
                              Download for iMac
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            {/* Curriculum */}
            <Typography.RegularText text="Curriculum" xStyle="mt-[50px]" />
            <div className="w-[888px]">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open
                          ? "flex w-full h-[44px] justify-between mt-[10px] px-[30px] py-[10px] rounded-t-[20px] shadow-sm bg-defaultWhite"
                          : "flex w-full h-[44px] justify-between mt-[10px] px-[30px] py-[10px] rounded-[20px] shadow-sm bg-defaultWhite"
                      }`}
                    >
                      <Typography.MediumText
                        text="Week 1"
                        xStyle="font-semibold"
                      />
                      <div className="flex gap-[30px] items-center">
                        <Typography.MediumText
                          text="Estimated time: 1hr 30min"
                          xStyle="font-semibold"
                        />
                        <ChevronDownIcon className="h-5 w-5 text-defaultText" />
                      </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-[30px] bg-defaultWhite rounded-b-[20px]">
                      <div>
                        <Typography.MediumText
                          text="Introduction to Electronics"
                          xStyle="font-semibold text-defaultBlue"
                        />

                        <Row className="mt-[28px]">
                          <Col md={5}>
                            <Typography.MediumText text="Tasks" />
                            <div className="flex gap-[20px]">
                              <div className="mt-[20px] p-3 w-[140px]">
                                <div className="flex gap-[10px]">
                                  <BookOpenIcon className="w-5 h-5 text-secondary-green" />
                                  <Typography.MediumText
                                    text="2 Readings"
                                    xStyle="font-semibold text-defaultBlue"
                                  />
                                </div>
                                <div className="bg-gray-200 h-[4px] rounded-[5px] my-[7px] w-full">
                                  <div
                                    className="bg-defaultBlue h-[4px] rounded-[5px]"
                                    style={{ width: `5%` }}
                                  ></div>
                                </div>
                                <Typography.ExtraSmallText text="10 mins left" />
                              </div>
                              <div className="mt-[20px] p-3 w-[131px]">
                                <div className="flex gap-[10px]">
                                  <VideoCameraIcon className="w-5 h-5 text-tertiary-purple" />
                                  <Typography.MediumText
                                    text="2 Videos"
                                    xStyle="font-semibold text-defaultBlue"
                                  />
                                </div>
                                <div className="bg-gray-200 h-[4px] rounded-[5px] my-[7px] w-full">
                                  <div
                                    className="bg-defaultBlue h-[4px] rounded-[5px]"
                                    style={{ width: `25%` }}
                                  ></div>
                                </div>
                                <Typography.ExtraSmallText text="10 mins left" />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <Typography.MediumText text="Requirement" />
                            <div className="mt-[20px] p-3 border-[1.5px] border-l-defaultBlue_40 border-r-0 border-y-0">
                              <div className="flex gap-[10px]">
                                <ClipboardDocumentIcon className="w-5 h-5 text-secondary-pink" />
                                <Typography.MediumText
                                  text="Quiz"
                                  xStyle="font-semibold text-defaultBlue"
                                />
                              </div>
                              <Typography.SmallText text="Topic here" />
                              <Typography.ExtraSmallText text="4:25 GMT" />
                            </div>
                          </Col>
                          <Col md={2}>
                            <Typography.MediumText text="Grade" />
                            <div className="mt-[20px] p-3 border-[1.5px] border-l-defaultBlue_40 border-r-0 border-y-0">
                              ---
                            </div>
                          </Col>
                          <Col md={2}>
                            <Typography.MediumText text="Due Time" />
                            <div className="mt-[20px] p-3 border-[1.5px] border-l-defaultBlue_40 border-r-0 border-y-0">
                              <div className="flex gap-[10px]">
                                <ClockIcon className="w-5 h-5 text-defaultYellow" />
                                <Typography.MediumText
                                  text="14 May"
                                  xStyle="font-semibold"
                                />
                              </div>
                              <Typography.ExtraSmallText text="4:25 GMT" />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>

            {/* down buttons */}
            <div className="items-center mt-[30px] w-[888px]">
              <div className="flex justify-between items-center">
                <button className="flex gap-[10px] text-defaultBlue font-semibold">
                  <HandThumbUpIcon className="w-5 h-5" />
                  Like
                </button>
                <button className="flex gap-[10px] text-defaultBlue font-semibold">
                  <HandThumbDownIcon className="w-5 h-5" />
                  Dislike
                </button>
                <button className="flex gap-[10px] text-defaultBlue font-semibold">
                  <FlagIcon className="w-5 h-5" />
                  Report an issue
                </button>
                <button className="flex gap-[10px] text-defaultBlue font-semibold">
                  <ShareIcon className="w-5 h-5" />
                  Share
                </button>
                <button className="text-defaultWhite bg-defaultBlue w-[150px] h-[40px] rounded-[30px]">
                  Start Learning
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
