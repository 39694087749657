import { Typography } from "../../components/typography/Typography";
import { Image } from "react-bootstrap";

const Parents = () => {
  return (
    <div className="sstep5">
      <Typography.MediumText
        text="Parents"
        xStyle="text-defaultText font-semibold"
      />

      <EachParent
        image="https://www.timeforkids.com/wp-content/uploads/2022/02/KOTY4.jpg"
        childName="Mawuli Togbor"
        childID="MBX95386p4509"
      />
      <EachParent
        image="https://www.incimages.com/uploaded_files/image/1920x1080/getty_168318604_69878.jpg"
        childName="Mawuli Togbor"
        childID="MBX95386p4509"
      />
    </div>
  );
};

interface parentProps {
  image: any;
  childName: String;
  childID: String;
}

//child component
const EachParent = ({ image, childName, childID }: parentProps) => {
  return (
    <div className="mt-[13px] flex gap-[18px] items-center">
      <Image
        className="w-[46px] h-[46px] object-cover rounded-full"
        src={image}
        alt="..."
        fluid
      />

      <div>
        <Typography.SmallText text={childName} xStyle="text-defaultText" />
        <Typography.SmallText text={childID} xStyle="text-defaultBlue" />
      </div>
    </div>
  );
};

export default Parents;
