import { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import { Typography } from "../../components/typography/Typography";
import SmallCalendar from "./SmallCalendar";
import { AllCards } from "../../components/cards/AllCards";
import { Col, Image, Row } from "react-bootstrap";
import { OtherButtons } from "../../components/buttons/OtherButtons";
import HelloCard from "./HelloCard";
import CompletedCourses from "./CompletedCourses";
import LessonsCompleted from "./LessonsCompleted";
import TasksCompleted from "./TasksCompleted";
import { isTourComplete } from "../../utils/isTourComplete";
import Joyride from "react-joyride";
import { dashboardTour } from "../../utils/tourSteps";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import { useNavigate } from "react-router-dom";
import TodaysTask from "./TodaysTask";

const Dashboard = () => {
  const navigate = useNavigate();
  //tour steps
  const [steps] = useState(dashboardTour);
  const [startTour, setStartTour] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStartTour(true);
    }, 1000);
  }, []);

  return (
    <>
      <div className="flex flex-row overflow-hidden h-[100vh] w-full">
        {isTourComplete ? (
          <></>
        ) : (
          <Joyride
            steps={steps}
            run={startTour}
            showSkipButton
            continuous={true}
            disableOverlayClose={true}
            showProgress
            disableCloseOnEsc={true}
            floaterProps={{ placement: "right" }}
            styles={{
              options: {
                arrowColor: "rgba(0, 0, 0, 0.80)",
                backgroundColor: "rgba(0, 0, 0, 0.80)",
                overlayColor: "rgba(0, 0, 0, 0.80)",
                primaryColor: "#6666EA",
                textColor: "#fff",
                zIndex: 1000,
              },
            }}
          />
        )}
        {/* Sidebar */}
        <ProSideBar dashboardActive="active" dashIcon="dashIconActive" />

        {/* Content */}
        <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
          <Navbar />

          {/* main content */}
          <div className="container-fluid pt-2 px-[16px] pb-[20px] h-full overflow-y-auto">
            <Typography.HeadingThree text="Dashboard" />

            <Row>
              {/* left column items */}
              <Col className="mt-[11px]" lg={9}>
                <HelloCard />

                {/* your activities */}
                <Row className="mt-[60px] dstep2">
                  <Typography.HeadingFour text="Your Activities" />

                  <CompletedCourses />
                  <LessonsCompleted />
                  <TasksCompleted />
                </Row>

                {/* Your courses */}
                <Row className="mt-[30px] dstep3">
                  <div className="flex justify-between items-center py-[5px]">
                    <Typography.HeadingFour text="Your Courses" />
                    <OtherButtons.SeeAll
                      btnName="See all"
                      onClick={() => navigate("/courses")}
                    />
                  </div>

                  <Col md={6} className="mt-[10px]">
                    <AllCards.CurrentCourses
                      image={
                        <Image
                          src="https://img.i-scmp.com/cdn-cgi/image/fit=contain,width=1098,format=auto/sites/default/files/styles/1200x800/public/d8/images/canvas/2022/07/22/e4eed890-84f6-4d40-887e-345e96d59789_7b99bf05.jpg"
                          className="rounded-[12px] w-[96px] h-[84px] object-cover"
                          fluid
                        />
                      }
                      courseTitle="Robotics"
                      details1="3 New Assignment"
                      details2="1 video to watch"
                      percent={44}
                    />
                  </Col>

                  <Col md={6} className="mt-[10px]">
                    <AllCards.CurrentCourses
                      image={
                        <Image
                          src="https://img.i-scmp.com/cdn-cgi/image/fit=contain,width=1098,format=auto/sites/default/files/styles/1200x800/public/d8/images/canvas/2022/07/22/e4eed890-84f6-4d40-887e-345e96d59789_7b99bf05.jpg"
                          className="rounded-[12px] w-[96px] h-[84px] object-cover"
                          fluid
                        />
                      }
                      courseTitle="Robotics"
                      details1="3 New Assignment"
                      details2="1 video to watch"
                      percent={75}
                    />
                  </Col>
                </Row>
              </Col>

              {/* right column items */}
              <Col className="mt-[11px]" lg={3}>
                {/* calendar section */}
                <SmallCalendar />

                <hr />

                {/* todays tasks */}
                <div className="mt-[20px] h-[170px] mb-[10px] dstep5">
                  <Typography.MediumText
                    text="Today's Tasks"
                    xStyle="font-semibold mb-[8px]"
                  />

                  <TodaysTask />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
