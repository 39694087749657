import { Bars3Icon } from "@heroicons/react/24/outline";

interface menuProps {
  onClick?: any;
}

const HamburgerMenu = ({ onClick }: menuProps) => {
  return (
    <div className="lg:hidden">
      <Bars3Icon
        className="w-[30px] h-[30px] text-defaultBlue"
        onClick={onClick}
      />
    </div>
  );
};

export default HamburgerMenu;
